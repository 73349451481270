// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Modal = require("@mui/joy/Modal").default;
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var Close = require("@mui/icons-material/Close").default;

function Dialog(props) {
  var __zIndex = props.zIndex;
  var __modalDrawer = props.modalDrawer;
  var __noGap = props.noGap;
  var __contained = props.contained;
  var __children = props.children;
  var __titleDescId = props.titleDescId;
  var __titleId = props.titleId;
  var onClose = props.onClose;
  var __role = props.role;
  var __size = props.size;
  var titleStartDecorator = props.titleStartDecorator;
  var description = props.description;
  var __title = props.title;
  var title = __title !== undefined ? __title : "";
  var size = __size !== undefined ? __size : "md";
  var role = __role !== undefined ? __role : "dialog";
  var titleId = __titleId !== undefined ? __titleId : "modal-title";
  var titleDescId = __titleDescId !== undefined ? __titleDescId : "modal-description";
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var contained = __contained !== undefined ? __contained : true;
  var noGap = __noGap !== undefined ? __noGap : false;
  var modalDrawer = __modalDrawer !== undefined ? __modalDrawer : false;
  var zIndex = __zIndex !== undefined ? __zIndex : 1300;
  return JsxRuntime.jsx(Modal, {
              open: props.open_,
              onClose: onClose,
              "aria-labelledby": titleId,
              "aria-describedby": titleDescId,
              sx: {
                zIndex: zIndex
              },
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: {
                      xs: modalDrawer ? "transparent" : "rgba(16, 13, 12, 0.7)",
                      md: "rgba(16, 13, 12, 0.7)"
                    }
                  }
                },
                content: {
                  sx: {
                    ml: "8px",
                    mr: "8px"
                  }
                }
              },
              disablePortal: modalDrawer,
              children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                        size: size,
                        variant: "outlined",
                        sx: {
                          width: "95%",
                          maxWidth: contained ? "30rem" : "90%",
                          padding: "0.75rem",
                          gap: noGap ? "0" : "0.75rem 1rem",
                          display: {
                            xs: modalDrawer ? "none" : "flex",
                            md: "flex"
                          }
                        },
                        role: role,
                        children: [
                          JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                children: [
                                  titleStartDecorator !== undefined ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                          children: Caml_option.some(Caml_option.valFromOption(titleStartDecorator)),
                                          xs: 2
                                        }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "title-lg",
                                                  id: titleId,
                                                  children: Caml_option.some(title)
                                                })),
                                        sx: {
                                          display: "flex",
                                          alignItems: "center",
                                          pl: 2
                                        },
                                        xs: titleStartDecorator !== undefined ? 8 : 10
                                      }),
                                  JsxRuntime.jsx(Joy$Util.Grid.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                                  onClick: onClose,
                                                  size: "sm",
                                                  children: Caml_option.some(JsxRuntime.jsx(Close, {}))
                                                })),
                                        sx: {
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center"
                                        },
                                        xs: 2
                                      })
                                ],
                                container: true,
                                sx: {
                                  flexGrow: 1,
                                  pb: noGap ? "0.75rem" : ""
                                }
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                sx: {
                                  px: 2,
                                  pb: 2,
                                  overflow: "auto"
                                },
                                children: [
                                  description !== undefined ? JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          textColor: "text.tertiary",
                                          id: titleDescId,
                                          children: Caml_option.some(Caml_option.valFromOption(description))
                                        }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                                  children
                                ]
                              })
                        ]
                      }))
            });
}

var make = Dialog;

exports.make = make;
/* Joy-Util Not a pure module */
