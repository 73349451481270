// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var Card = require("@mui/joy/Card").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var Countdown$Thin = require("./Countdown.bs.js");
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var SalesCabin$Thin = require("../../../utils/sales-flow/SalesCabin.bs.js");
var Divider = require("@mui/joy/Divider").default;
var Checkbox = require("@mui/joy/Checkbox").default;
var JsxRuntime = require("react/jsx-runtime");
var ReferralInput$Thin = require("../passengers/ReferralInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var CardActions = require("@mui/joy/CardActions").default;
var CardContent = require("@mui/joy/CardContent").default;
var CircularProgress = require("@mui/joy/CircularProgress").default;
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;
var SquareFootOutlined = require("@mui/icons-material/SquareFootOutlined").default;

function SelectedCabinSummaryCard$SummaryCardDetailsAndBillingLines(props) {
  var isMobile = props.isMobile;
  var quote = props.quote;
  var category = props.category;
  var tmp;
  var exit = 0;
  if (typeof quote !== "object") {
    exit = 1;
  } else {
    var mq = quote._0;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Stack, {
                  direction: "column",
                  spacing: 1,
                  sx: {
                    py: 2,
                    overflow: isMobile ? "auto" : "hidden"
                  },
                  children: Caml_option.some(Belt_Array.mapWithIndex(mq.quoteLines, (function (i, l) {
                              return JsxRuntime.jsx(JsxRuntime.Fragment, {
                                          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                    direction: "row",
                                                    justifyContent: "space-between",
                                                    spacing: 2,
                                                    sx: {
                                                      overflow: isMobile ? "inherit" : "hidden",
                                                      textOverflow: "ellipsis"
                                                    },
                                                    children: [
                                                      JsxRuntime.jsx(Typography, {
                                                            level: isMobile ? "body-xs" : "body-sm",
                                                            noWrap: !isMobile,
                                                            children: Caml_option.some(l.description)
                                                          }),
                                                      JsxRuntime.jsx(Typography, {
                                                            level: isMobile ? "body-xs" : "body-sm",
                                                            children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(l.amount)))
                                                          })
                                                    ]
                                                  }, String(i)))
                                        });
                            })))
                }),
            JsxRuntime.jsx(Divider, {}),
            JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  spacing: 2,
                  sx: {
                    py: 1
                  },
                  children: [
                    JsxRuntime.jsx(Typography, {
                          level: isMobile ? "body-sm" : "body-md",
                          children: "Total"
                        }),
                    JsxRuntime.jsx(Typography, {
                          level: isMobile ? "body-sm" : "body-md",
                          children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(mq.total)))
                        })
                  ]
                })
          ]
        });
  }
  if (exit === 1) {
    if (category !== undefined) {
      var referencePricePerBooking = category.referencePricePerBooking;
      var quotePricePerBooking = category.quotePricePerBooking;
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(Stack, {
                    direction: "column",
                    spacing: 1,
                    sx: {
                      py: 2
                    },
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              spacing: 2,
                              sx: {
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              },
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "body-sm",
                                      noWrap: true,
                                      children: Caml_option.some(props.fillerText)
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-sm",
                                      children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Belt_Option.getWithDefault(quotePricePerBooking, referencePricePerBooking))))
                                    })
                              ]
                            }))
                  }),
              JsxRuntime.jsx(Divider, {}),
              JsxRuntime.jsxs(Stack, {
                    direction: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    spacing: 2,
                    sx: {
                      py: 1
                    },
                    children: [
                      JsxRuntime.jsx(Typography, {
                            level: isMobile ? "body-sm" : "body-md",
                            children: "Total"
                          }),
                      JsxRuntime.jsx(Typography, {
                            level: isMobile ? "body-sm" : "body-md",
                            children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Belt_Option.getWithDefault(quotePricePerBooking, referencePricePerBooking))))
                          })
                    ]
                  })
            ]
          });
    } else {
      tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {
            children: Caml_option.some(JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 1,
                      sx: {
                        py: 2
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                noWrap: true,
                                children: "Loading pricing details..."
                              }))
                    }))
          });
    }
  }
  return JsxRuntime.jsx(CardContent, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        sx: {
                          pb: 3
                        },
                        children: [
                          category !== undefined ? (
                              isMobile ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: {
                                                xs: "column",
                                                md: "row"
                                              },
                                              spacing: 2,
                                              sx: {
                                                width: "auto",
                                                p: 1
                                              },
                                              children: [
                                                Belt_Option.mapWithDefault(props.image, null, (function (image) {
                                                        return JsxRuntime.jsx(Stack, {
                                                                    sx: {
                                                                      backgroundImage: "url(" + image.src + ")",
                                                                      backgroundSize: "cover",
                                                                      backgroundPosition: "center",
                                                                      width: "8rem",
                                                                      height: "6rem",
                                                                      borderRadius: "md"
                                                                    }
                                                                  });
                                                      })),
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "column",
                                                      spacing: 1,
                                                      children: [
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "title-lg",
                                                              component: "h3",
                                                              children: Caml_option.some(category.catName + " (" + CS_Slugs$Util.CabinCategorySlug.toString(category.slug).toUpperCase() + ")")
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "title-sm",
                                                              children: Caml_option.some(category.className)
                                                            }),
                                                        JsxRuntime.jsx(Stack, {
                                                              direction: "row",
                                                              spacing: 1,
                                                              useFlexGap: true,
                                                              flexWrap: "wrap",
                                                              sx: {
                                                                overflow: "hidden"
                                                              },
                                                              children: Caml_option.some(JsxRuntime.jsx(AppChip$Thin.make, {
                                                                        children: String(category.catMinSqFoot) + "-" + String(category.catMaxSqFoot) + " SQ FT",
                                                                        startDecorator: Caml_option.some(JsxRuntime.jsx(SquareFootOutlined, {}))
                                                                      }))
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx(Divider, {
                                              inset: "none"
                                            })
                                      ]
                                    })
                            ) : JsxRuntime.jsx(Stack, {
                                  direction: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  sx: {
                                    py: 2
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                                            size: "md",
                                            variant: "solid"
                                          }))
                                }),
                          tmp
                        ]
                      })),
              sx: {
                "overflow-x": isMobile ? "wrap" : "auto",
                "overflow-y": "auto",
                "max-height": isMobile ? "6rem" : "100%"
              }
            });
}

var SummaryCardDetailsAndBillingLines = {
  make: SelectedCabinSummaryCard$SummaryCardDetailsAndBillingLines
};

function SelectedCabinSummaryCard(props) {
  var occupancy = props.occupancy;
  var __showReferral = props.showReferral;
  var btnAdvanceCmp = props.btnAdvanceCmp;
  var mustAcceptTermsLink = props.mustAcceptTermsLink;
  var quote = props.quote;
  var advanceText = props.advanceText;
  var onAdvance = props.onAdvance;
  var canAdvance = props.canAdvance;
  var cabinHoldExpiry = props.cabinHoldExpiry;
  var category = props.category;
  var showReferral = __showReferral !== undefined ? __showReferral : false;
  var match = React.useState(function () {
        return false;
      });
  var setFixed = match[1];
  var fixed = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setTermsAccepted = match$1[1];
  var termsAccepted = match$1[0];
  var match$2 = React.useState(function () {
        return 0.0;
      });
  var setParentWidth = match$2[1];
  var parentWidth = match$2[0];
  var match$3 = AppConfig$Thin.Context.use();
  var brandFamilyName = match$3.brandFamily.brandFamilyName;
  var disabled = props.pageLocked || !canAdvance || Belt_Option.isSome(mustAcceptTermsLink) && !termsAccepted;
  var parentRef = React.useRef(null);
  var gridWidth = 5.0 / 12.0 * 100.0;
  var catSlug = category !== undefined ? CS_Slugs$Util.CabinCategorySlug.toPrimitive(category.slug).toUpperCase() : undefined;
  var fillerText = "Your quoted price " + Belt_Option.getWithDefault(Belt_Option.map(catSlug, (function (c) {
              return " (" + c + ")";
            })), "") + " for " + String(occupancy) + " guest" + (
    occupancy > 1 ? "s" : ""
  );
  var match$4 = React.useState(function () {
        return false;
      });
  var setSliderOpen = match$4[1];
  var sliderOpen = match$4[0];
  React.useEffect((function () {
          var toggleFixed = function () {
            var parent = parentRef.current;
            Belt_Option.map((parent == null) ? undefined : Caml_option.some(parent), (function (el) {
                    var rect = el.getBoundingClientRect();
                    var shouldStick = rect.top <= 16.0;
                    if (shouldStick) {
                      setFixed(function (param) {
                            return true;
                          });
                    } else {
                      setFixed(function (param) {
                            return false;
                          });
                    }
                  }));
          };
          addEventListener("scroll", toggleFixed, true);
          return (function () {
                    removeEventListener("scroll", toggleFixed, true);
                  });
        }), []);
  React.useEffect((function () {
          var setParentWidth$1 = function () {
            var parent = parentRef.current;
            Belt_Option.map((parent == null) ? undefined : Caml_option.some(parent), (function (el) {
                    var rect = el.getBoundingClientRect();
                    var parentWidth = rect.right - rect.left;
                    setParentWidth(function (param) {
                          return parentWidth;
                        });
                  }));
          };
          setParentWidth$1();
          addEventListener("resize", setParentWidth$1, true);
          return (function () {
                    removeEventListener("resize", setParentWidth$1, true);
                  });
        }), []);
  var scimages = SalesCabin$Thin.useSalesCabinLinkedImagesForVariant(Belt_Option.flatMap(category, (function (v) {
              return v.imageSetSlug;
            })), "Aspect13to10");
  var image = Belt_Array.get(scimages, 0);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      ref: parentRef,
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                sx: {
                                  display: {
                                    xs: "none",
                                    md: "flex"
                                  }
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          spacing: 2,
                                          sx: {
                                            maxHeight: "calc(95vh - " + String(72.0 + 16.0) + "px)",
                                            position: fixed ? "fixed" : "static",
                                            top: fixed ? 16.0 : 0.0
                                          },
                                          children: [
                                            JsxRuntime.jsxs(Card, {
                                                  children: [
                                                    JsxRuntime.jsxs(Stack, {
                                                          direction: "row",
                                                          justifyContent: "space-between",
                                                          alignItems: "center",
                                                          sx: {
                                                            py: 1
                                                          },
                                                          children: [
                                                            JsxRuntime.jsx(Typography, {
                                                                  level: "h3",
                                                                  children: "Summary"
                                                                }),
                                                            JsxRuntime.jsxs(Stack, {
                                                                  direction: "row",
                                                                  spacing: 1,
                                                                  children: [
                                                                    cabinHoldExpiry !== undefined ? JsxRuntime.jsx(Typography, {
                                                                            level: "body-sm",
                                                                            children: "Stateroom held for "
                                                                          }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}),
                                                                    JsxRuntime.jsx(Countdown$Thin.make, {
                                                                          cabinHoldExpiry: cabinHoldExpiry
                                                                        })
                                                                  ]
                                                                })
                                                          ]
                                                        }),
                                                    JsxRuntime.jsx(SelectedCabinSummaryCard$SummaryCardDetailsAndBillingLines, {
                                                          category: category,
                                                          image: image,
                                                          quote: quote,
                                                          fillerText: fillerText,
                                                          isMobile: false
                                                        }),
                                                    JsxRuntime.jsx(CardActions, {
                                                          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                                    spacing: 2,
                                                                    sx: {
                                                                      pb: 1,
                                                                      width: "100%"
                                                                    },
                                                                    children: [
                                                                      mustAcceptTermsLink !== undefined ? JsxRuntime.jsxs(Stack, {
                                                                              direction: "row",
                                                                              alignItems: "center",
                                                                              spacing: 1,
                                                                              sx: {
                                                                                pb: 3
                                                                              },
                                                                              children: [
                                                                                JsxRuntime.jsx(Checkbox, {
                                                                                      checked: termsAccepted,
                                                                                      onChange: (function (param) {
                                                                                          setTermsAccepted(function (t) {
                                                                                                return !t;
                                                                                              });
                                                                                        })
                                                                                    }),
                                                                                JsxRuntime.jsxs(Typography, {
                                                                                      level: "body-xs",
                                                                                      children: [
                                                                                        "I understand that by proceeding I agree to be bound by the " + brandFamilyName,
                                                                                        JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                                              children: "terms and conditions",
                                                                                              href: mustAcceptTermsLink,
                                                                                              target: "_blank"
                                                                                            }),
                                                                                        " and the terms and conditions of my selected payment processor."
                                                                                      ]
                                                                                    })
                                                                              ]
                                                                            }) : null,
                                                                      JsxRuntime.jsx(Button, {
                                                                            fullWidth: true,
                                                                            onClick: (function (param) {
                                                                                if (disabled) {
                                                                                  return ;
                                                                                } else {
                                                                                  return onAdvance();
                                                                                }
                                                                              }),
                                                                            disabled: disabled,
                                                                            sx: {
                                                                              borderRadius: "6px"
                                                                            },
                                                                            children: Caml_option.some(btnAdvanceCmp !== undefined ? Caml_option.valFromOption(btnAdvanceCmp) : (
                                                                                    advanceText !== undefined ? advanceText : "Next"
                                                                                  ))
                                                                          })
                                                                    ]
                                                                  }))
                                                        })
                                                  ],
                                                  sx: {
                                                    p: 4,
                                                    border: "1px solid",
                                                    borderColor: "neutral.300",
                                                    backgroundColor: "background.surface",
                                                    borderRadius: "12px",
                                                    width: fixed ? (
                                                        parentWidth === 0.0 ? "clamp(0px, calc(" + String(gridWidth) + "% - 98px), 570px)" : String(parentWidth - 66.0) + "px"
                                                      ) : "auto"
                                                  }
                                                }),
                                            JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    width: fixed ? String(parentWidth) + "px" : "auto"
                                                  },
                                                  children: Caml_option.some(showReferral ? JsxRuntime.jsx(ReferralInput$Thin.make, {
                                                              sessionData: props.sessionData,
                                                              useSessionSaveHandler: props.useSessionSaveHandler
                                                            }) : null)
                                                })
                                          ]
                                        }))
                              }))
                    }),
                JsxRuntime.jsxs(Stack, {
                      spacing: 1,
                      sx: {
                        py: 2,
                        px: 2,
                        backgroundColor: "background.surface",
                        borderTop: "1px solid",
                        borderColor: "neutral.300",
                        display: {
                          xs: "flex",
                          md: "none"
                        },
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0
                      },
                      children: [
                        mustAcceptTermsLink !== undefined && canAdvance ? JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                children: "Billing Summary",
                                                onClick: (function (param) {
                                                    setSliderOpen(function (param) {
                                                          return !sliderOpen;
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx(KeyboardArrowDown, {
                                                fontSize: "lg",
                                                sx: {
                                                  transform: sliderOpen ? "rotate(0deg)" : "rotate(-90deg)"
                                                },
                                                onClick: (function (param) {
                                                    setSliderOpen(function (param) {
                                                          return !sliderOpen;
                                                        });
                                                  })
                                              })
                                        ]
                                      }),
                                  sliderOpen ? JsxRuntime.jsx(SelectedCabinSummaryCard$SummaryCardDetailsAndBillingLines, {
                                          category: category,
                                          image: image,
                                          quote: quote,
                                          fillerText: fillerText,
                                          isMobile: true
                                        }) : null,
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        spacing: 1,
                                        sx: {
                                          py: 1
                                        },
                                        children: [
                                          JsxRuntime.jsx(Checkbox, {
                                                checked: termsAccepted,
                                                onChange: (function (param) {
                                                    setTermsAccepted(function (t) {
                                                          return !t;
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsxs(Typography, {
                                                level: "body-xs",
                                                children: [
                                                  "I understand that by proceeding I agree to be bound by the " + brandFamilyName + " ",
                                                  JsxRuntime.jsx(Joy$Util.Link.make, {
                                                        children: "terms and conditions",
                                                        href: mustAcceptTermsLink,
                                                        target: "_blank"
                                                      }),
                                                  " and the terms and conditions of my selected payment processor."
                                                ]
                                              })
                                        ]
                                      })
                                ]
                              }) : null,
                        JsxRuntime.jsxs(Button, {
                              onClick: (function (param) {
                                  if (disabled) {
                                    return ;
                                  } else {
                                    return onAdvance();
                                  }
                                }),
                              disabled: disabled,
                              children: [
                                btnAdvanceCmp !== undefined ? Caml_option.valFromOption(btnAdvanceCmp) : (
                                    advanceText !== undefined ? advanceText : "Next"
                                  ),
                                JsxRuntime.jsx(Countdown$Thin.make, {
                                      cabinHoldExpiry: cabinHoldExpiry,
                                      inButton: true
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = SelectedCabinSummaryCard;

exports.SummaryCardDetailsAndBillingLines = SummaryCardDetailsAndBillingLines;
exports.make = make;
/* react Not a pure module */
