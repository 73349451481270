// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var ClaimTransferInvitationPage$Thin = require("../pages/ClaimTransferInvitationPage.bs.js");
var ClaimTransferInvitationModel$Thin = require("../utils/accounts/ClaimTransferInvitationModel.bs.js");
var ClaimTransferInvitationRouteQuery_graphql$Thin = require("../__generated__/ClaimTransferInvitationRouteQuery_graphql.bs.js");

var convertVariables = ClaimTransferInvitationRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimTransferInvitationRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimTransferInvitationRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ClaimTransferInvitationRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ClaimTransferInvitationRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ClaimTransferInvitationRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ClaimTransferInvitationRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ClaimTransferInvitationRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ClaimTransferInvitationRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ClaimTransferInvitationRoute(props) {
  var allegedToken = props.nav;
  var match = use({
        allegedToken: allegedToken
      }, undefined, undefined, undefined);
  var invitation = ClaimTransferInvitationModel$Thin.Query.Deref.use(match.fragmentRefs).derefClaimTransferAccountInvitation;
  var match$1 = CurrentUser$Thin.Context.use();
  var view = HopperState$Util.Observable.useStore((function () {
          return ClaimTransferInvitationModel$Thin.blankModel;
        }), undefined);
  var progressResult = HopperState$Util.Observable.useComputed(view, (function (param) {
          var claimProgress = param.claimProgress;
          if (typeof claimProgress !== "object") {
            return "Loading";
          }
          if (claimProgress.TAG !== "Success") {
            return "Loading";
          }
          switch (claimProgress._0) {
            case "Login" :
                return "Login";
            case "Verify" :
                return "Verify";
            default:
              return "Loading";
          }
        }), undefined, undefined);
  React.useEffect(function () {
        switch (progressResult) {
          case "Login" :
              HopperURL$Thin.redirectToPath(SysCtx$Thin.vars.accountLink, (function (urlState) {
                      return {
                              dismissibleAlerts: ["PleaseVerifyYourTransferAccount"],
                              loginRedirect: urlState.loginRedirect,
                              token: urlState.token,
                              landing: urlState.landing,
                              expiryMillis: urlState.expiryMillis
                            };
                    }), undefined);
              break;
          case "Verify" :
              HopperURL$Thin.redirectToPath(SysCtx$Thin.vars.accountLink, (function (urlState) {
                      return {
                              dismissibleAlerts: ["PleaseVerifyYourPrefilledAccount"],
                              loginRedirect: urlState.loginRedirect,
                              token: urlState.token,
                              landing: urlState.landing,
                              expiryMillis: urlState.expiryMillis
                            };
                    }), undefined);
              break;
          default:
            
        }
      });
  var tmp;
  var exit = 0;
  var tmp$1 = match$1.signOnStatus;
  if ((tmp$1 === "VERIFY_EMAIL" || tmp$1 === "INVITED" || tmp$1 === "PENDING_2FA" || tmp$1 === "CLEAR_JWT" || tmp$1 === "ANONYMOUS" || tmp$1 === "SIGNED_ON") && tmp$1 === "ANONYMOUS" && invitation !== undefined) {
    tmp = JsxRuntime.jsx(ClaimTransferInvitationPage$Thin.ClaimAnonymously.make, {
          view: view,
          transfer: invitation,
          allegedToken: allegedToken
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = invitation !== undefined ? JsxRuntime.jsx(ClaimTransferInvitationPage$Thin.MustBeLoggedOut.make, {
            allegedToken: allegedToken
          }) : JsxRuntime.jsx(ClaimTransferInvitationPage$Thin.InvitationNotFound.make, {
            allegedToken: allegedToken
          });
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(StandardLayout$Thin.make, {
                        dash: false,
                        children: tmp
                      }))
            });
}

var $$URL;

var make = ClaimTransferInvitationRoute;

exports.$$URL = $$URL;
exports.Query = Query;
exports.make = make;
/* use Not a pure module */
