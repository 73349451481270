// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var DraftUtils$Thin = require("../../../utils/booking/DraftUtils.bs.js");
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var ModalDrawer$Thin = require("../../common/molecules/ModalDrawer.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var InvoiceComparison$Thin = require("./InvoiceComparison.bs.js");
var ApplyBookingChanges$Thin = require("./mutations/ApplyBookingChanges.bs.js");

function PreviewChangesModal(props) {
  var draft = props.draftBilling;
  var refetch = props.refetch;
  var setDraftMode = props.setDraftMode;
  var onClose = props.onClose;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var applyArgs = props.getPreviewFromDiff(diff);
  var match = ApplyBookingChanges$Thin.useMutation(applyArgs, (function () {
          refetch();
          setDraftMode(function (param) {
                return false;
              });
          DraftUtils$Thin.addToDraft(BookingDiff$Thin.emptyDiff, url);
          onClose();
        }));
  var setError = match.setError;
  var onClose$1 = function () {
    setError(function (param) {
          
        });
    onClose();
  };
  return JsxRuntime.jsxs(ModalDrawer$Thin.make, {
              open_: props.open_,
              title: "Changes Summary",
              onClose: onClose$1,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(InvoiceComparison$Thin.make, {
                                current: props.currentBilling,
                                draft: draft,
                                error: match.error
                              })),
                      sx: {
                        overflow: "auto"
                      }
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%",
                                  p: {
                                    xs: 2,
                                    md: 0
                                  }
                                },
                                onSubmit: match.onSubmit,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: onClose$1,
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Keep editing"
                                      }),
                                  Belt_Option.isSome(draft) ? JsxRuntime.jsx(Button, {
                                          loading: match.isMutating,
                                          type: "submit",
                                          children: "Apply"
                                        }) : null
                                ]
                              }))
                    })
              ],
              contained: false,
              noGap: true
            });
}

var make = PreviewChangesModal;

exports.make = make;
/* @mui/joy/Stack Not a pure module */
