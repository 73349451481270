// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var DateFns$Util = require("./DateFns.bs.js");

function month(t) {
  return DateFns.getMonth(t);
}

function day(t) {
  return DateFns.getDate(t);
}

function year(t) {
  return DateFns.getYear(t);
}

function prettyFormatDateRange(l, r) {
  var match = DateFns.getYear(l) === DateFns.getYear(r);
  var match$1 = DateFns.getMonth(l) === DateFns.getMonth(r);
  if (match) {
    if (match$1) {
      return DateFns.format(l, "LLLL d") + " - " + DateFns.format(r, "d, yyyy");
    } else {
      return DateFns.format(l, "LLLL d") + " - " + DateFns.format(r, "LLLL d, yyyy");
    }
  } else {
    return DateFns.format(l, "LLLL d yyyy") + " - " + DateFns.format(r, "LLLL d yyyy");
  }
}

function format(prim0, prim1) {
  return DateFns.format(prim0, prim1);
}

function differenceInCalendarDays(prim0, prim1) {
  return DateFns.differenceInCalendarDays(prim0, prim1);
}

function differenceInDays(prim0, prim1) {
  return DateFns.differenceInDays(prim0, prim1);
}

function differenceInWeeks(prim0, prim1) {
  return DateFns.differenceInWeeks(prim0, prim1);
}

function differenceInMonths(prim0, prim1) {
  return DateFns.differenceInMonths(prim0, prim1);
}

function today() {
  return new Date();
}

function fromDate(t) {
  return t;
}

function toDate(t) {
  return t;
}

var daysUntil = DateFns$Util.daysUntil;

var nonCalendarDaysUntil = DateFns$Util.nonCalendarDaysUntil;

var weeksUntil = DateFns$Util.weeksUntil;

var monthsUntil = DateFns$Util.monthsUntil;

var getRemainingYearsMonthsDays = DateFns$Util.getRemainingYearsMonthsDays;

exports.month = month;
exports.day = day;
exports.year = year;
exports.prettyFormatDateRange = prettyFormatDateRange;
exports.format = format;
exports.daysUntil = daysUntil;
exports.nonCalendarDaysUntil = nonCalendarDaysUntil;
exports.weeksUntil = weeksUntil;
exports.monthsUntil = monthsUntil;
exports.differenceInCalendarDays = differenceInCalendarDays;
exports.differenceInDays = differenceInDays;
exports.differenceInWeeks = differenceInWeeks;
exports.differenceInMonths = differenceInMonths;
exports.getRemainingYearsMonthsDays = getRemainingYearsMonthsDays;
exports.today = today;
exports.fromDate = fromDate;
exports.toDate = toDate;
/* date-fns Not a pure module */
