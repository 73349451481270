// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"derefClaimTransferAccountInvitation_obfuscatedEmail":{"c":"Util.CustomScalars.Email.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "allegedToken"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClaimTransferInvitationModelDeref_frag",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "allegedToken",
          "variableName": "allegedToken"
        }
      ],
      "concreteType": "ClaimTransferAccountInvitationMeta",
      "kind": "LinkedField",
      "name": "derefClaimTransferAccountInvitation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "obfuscatedEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserClaimGatedField",
          "kind": "LinkedField",
          "name": "gatedFields",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasAnyGatedFields",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isLastNameGated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBirthdateGated",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
