// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var $$String = require("rescript/lib/js/string.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Http$Util = require("util/src/Http.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("./SysCtx.bs.js");
var Chip = require("@mui/joy/Chip").default;
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;

function phoneValidation(cc, phone) {
  if (phone === "") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return Belt_Result.map(CS_Phone$Util.Phone.fromPrimitive(cc + " " + phone), (function (v) {
                  return Caml_option.some(v);
                }));
  }
}

function validatePassword(s) {
  if ($$String.trim(s) === "") {
    return {
            TAG: "Error",
            _0: "Password cannot be entirely whitespace"
          };
  } else if (s.length < 10) {
    return {
            TAG: "Error",
            _0: "Password must be " + String(10) + " or more characters"
          };
  } else {
    return {
            TAG: "Ok",
            _0: s
          };
  }
}

function isPasswordValid(s) {
  return Belt_Result.isOk(validatePassword(s));
}

function cookieLogin(credentials, onSuccessOpt, onErrorOpt) {
  var onSuccess = onSuccessOpt !== undefined ? Caml_option.valFromOption(onSuccessOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  return Http$Util.Login.withPassword(process.env.THIN_LOGIN_ABSPATH, credentials, Caml_option.some(onSuccess), Caml_option.some(onError), undefined);
}

function cookieLogout(onSuccessOpt, onErrorOpt) {
  var onSuccess = onSuccessOpt !== undefined ? Caml_option.valFromOption(onSuccessOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  return Http$Util.Login.logout(process.env.THIN_LOGOUT_ABSPATH, Caml_option.some(onSuccess), Caml_option.some(onError), true);
}

function useStateExtended(useStateArgs) {
  var match = React.useState(useStateArgs);
  var set = match[1];
  var onChange = function (e) {
    set(e.currentTarget.value);
  };
  return [
          match[0],
          set,
          onChange
        ];
}

function formatCurrency($$float) {
  var options = {
    style: "currency",
    currency: SysCtx$Thin.vars.currency
  };
  var formatter = Intl.NumberFormat(SysCtx$Thin.vars.locale, options);
  return formatter.format($$float);
}

function intsToCents(v) {
  return v / 100.0;
}

function emailStringBreak(email) {
  var array = (function (string) {
        return Js_string.split("@", string);
      })((function (array) {
            return Js_array.joinWith("\u200b.", array);
          })((function (string) {
                return Js_string.split(".", string);
              })(email)));
  return Js_array.joinWith("\u200b@", array);
}

var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

async function copyToClipboard(s, fn) {
  try {
    await window.navigator.clipboard.writeText(s);
    fn(function (param) {
          return true;
        });
    setTimeout((function () {
            fn(function (param) {
                  return false;
                });
          }), 1500);
    return ;
  }
  catch (exn){
    console.log(Txt$Thin.Clipboard.copyError.value);
    return ;
  }
}

var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");

function isValid(email) {
  var match = regex.exec(email);
  return match !== null;
}

var Email = {
  regex: regex,
  isValid: isValid
};

function toString(status) {
  if (!(status === "CANCELLED" || status === "COMPLETED" || status === "PENDING" || status === "NASCENT" || status === "ERROR")) {
    return "Unknown";
  }
  switch (status) {
    case "NASCENT" :
        return "Unknown";
    case "PENDING" :
        return "Pending";
    case "COMPLETED" :
        return "Completed";
    case "CANCELLED" :
        return "Canceled";
    case "ERROR" :
        return "Error";
    
  }
}

function shouldRenderStatus(status) {
  if ((status === "CANCELLED" || status === "COMPLETED" || status === "PENDING" || status === "NASCENT" || status === "ERROR") && status === "COMPLETED") {
    return false;
  } else {
    return true;
  }
}

var PmtLifecycle = {
  toString: toString,
  shouldRenderStatus: shouldRenderStatus
};

var Voyage = {};

var cmp = Caml_obj.compare;

var StrCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

function toString$1(g) {
  if (!(g === "F" || g === "M" || g === "X")) {
    return "Unknown";
  }
  switch (g) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

var Gender = {
  toString: toString$1
};

function getBackgroundColor(color) {
  switch (color) {
    case "Neutral" :
        return "#F0F3F5";
    case "Warning" :
        return "#FFC82C";
    case "Warning2" :
        return "#FFF9EB";
    case "Danger" :
        return "#DE4F48";
    case "Danger2" :
        return "#FBF2F2";
    case "Success" :
        return "#E7FDF1";
    case "Clear" :
        return "transparent";
    
  }
}

function getColorNoSuccess(color) {
  switch (color) {
    case "Warning2" :
        return "#916A08";
    case "Danger" :
        return "#FFFFFF";
    case "Danger2" :
        return "#BF1414";
    default:
      return "#000000";
  }
}

function getColorWithSuccess(color) {
  switch (color) {
    case "Warning2" :
        return "#916A08";
    case "Danger" :
        return "#FFFFFF";
    case "Danger2" :
        return "#BF1414";
    case "Success" :
        return "#037E3A";
    default:
      return "#000000";
  }
}

function getBorderColor(color) {
  switch (color) {
    case "Neutral" :
        return "#93A5B1";
    case "Warning2" :
        return "#DBA313";
    case "Clear" :
        return "transparent";
    default:
      return getColorNoSuccess(color);
  }
}

var NotificationColor = {
  getBackgroundColor: getBackgroundColor,
  getColorNoSuccess: getColorNoSuccess,
  getColorWithSuccess: getColorWithSuccess,
  getBorderColor: getBorderColor
};

function toString$2(status) {
  if (typeof status !== "object") {
    return "TBA";
  }
  if (status.TAG === "Status") {
    var tmp = status._0;
    if (!(tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED")) {
      return "Unknown";
    }
    switch (tmp) {
      case "CONFIRMED" :
          return "Confirmed";
      case "INCOMPLETE" :
          return "Incomplete";
      case "INVITED" :
          return "Invited";
      
    }
  } else {
    switch (status._0) {
      case "Invite" :
          return "Invite on Apply";
      case "Replace" :
          return "Replace on Apply";
      case "Attach" :
          return "Attach on Apply";
      
    }
  }
}

function getPrimaryColor(status) {
  switch (status) {
    case "Confirmed" :
        return "#037E3A";
    case "Incomplete" :
    case "TBA" :
        return "#B77401";
    case "Attach on Apply" :
    case "Invite on Apply" :
    case "Invited" :
    case "Replace on Apply" :
    case "Update on Apply" :
        return "#30557A";
    default:
      return "#FF4949";
  }
}

function getBackgroundColor$1(status) {
  switch (status) {
    case "Confirmed" :
        return "#E7FDF1";
    case "Incomplete" :
    case "TBA" :
        return "#FFF9EB";
    case "Attach on Apply" :
    case "Invite on Apply" :
    case "Invited" :
    case "Replace on Apply" :
    case "Update on Apply" :
        return "#F0F3F5";
    default:
      return "#FBF2F2";
  }
}

function makeChip(sizeOpt, status) {
  var size = sizeOpt !== undefined ? sizeOpt : "sm";
  var tmp;
  if (typeof status !== "object") {
    tmp = JsxRuntime.jsx(WarningAmber, {});
  } else if (status.TAG === "Status") {
    var tmp$1 = status._0;
    if (tmp$1 === "INCOMPLETE" || tmp$1 === "CONFIRMED" || tmp$1 === "INVITED") {
      switch (tmp$1) {
        case "CONFIRMED" :
            tmp = JsxRuntime.jsx(CheckCircleOutline, {});
            break;
        case "INCOMPLETE" :
            tmp = JsxRuntime.jsx(WarningAmber, {});
            break;
        case "INVITED" :
            tmp = JsxRuntime.jsx(ScheduleSendOutlined, {});
            break;
        
      }
    } else {
      tmp = null;
    }
  } else {
    tmp = JsxRuntime.jsx(ModeEditOutlined, {});
  }
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some(toString$2(status)),
              size: size,
              startDecorator: Caml_option.some(tmp),
              sx: {
                color: getPrimaryColor(toString$2(status)),
                backgroundColor: getBackgroundColor$1(toString$2(status)),
                borderRadius: "xs"
              }
            });
}

function fromInput(status) {
  return status;
}

function toInput(status) {
  return status;
}

var PaxDataStatus = {
  toString: toString$2,
  getPrimaryColor: getPrimaryColor,
  getBackgroundColor: getBackgroundColor$1,
  makeChip: makeChip,
  fromInput: fromInput,
  toInput: toInput
};

var passwordLength = 10;

exports.passwordLength = passwordLength;
exports.phoneValidation = phoneValidation;
exports.validatePassword = validatePassword;
exports.isPasswordValid = isPasswordValid;
exports.cookieLogin = cookieLogin;
exports.cookieLogout = cookieLogout;
exports.useStateExtended = useStateExtended;
exports.formatCurrency = formatCurrency;
exports.intsToCents = intsToCents;
exports.emailStringBreak = emailStringBreak;
exports.$$Window = $$Window;
exports.copyToClipboard = copyToClipboard;
exports.Email = Email;
exports.PmtLifecycle = PmtLifecycle;
exports.Voyage = Voyage;
exports.StrCmp = StrCmp;
exports.Gender = Gender;
exports.NotificationColor = NotificationColor;
exports.PaxDataStatus = PaxDataStatus;
/* regex Not a pure module */
