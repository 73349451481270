// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperURL$Thin = require("../../context/HopperURL.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var NewPasswordModel$Thin = require("./NewPasswordModel.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var ClaimNewPaxInvitationModel$Thin = require("./ClaimNewPaxInvitationModel.bs.js");
var ClaimTransferInvitationModelDeref_frag_graphql$Thin = require("../../__generated__/ClaimTransferInvitationModelDeref_frag_graphql.bs.js");
var ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin = require("../../__generated__/ClaimTransferInvitationModel_ClaimAnonMutation_graphql.bs.js");

var baseSlug = "claim-transfer";

function toList(uuid) {
  return {
          hd: baseSlug,
          tl: {
            hd: CS_UUID$Util.PlainUUID.toString(uuid),
            tl: /* [] */0
          }
        };
}

function toString(nav) {
  return "/" + Belt_List.toArray(toList(nav)).join("/");
}

function match(pathList) {
  if (pathList && pathList.hd === baseSlug) {
    var lst = pathList.tl;
    if (lst && !lst.tl) {
      return Belt_Result.mapWithDefault(CS_UUID$Util.PlainUUID.fromPrimitive(lst.hd), undefined, (function (v) {
                    return Caml_option.some(v);
                  }));
    } else {
      return ;
    }
  }
  
}

function redirect(state) {
  HopperURL$Thin.redirectToPath(toString(state), (function (param) {
          return HopperURL$Thin.blankGlobalQueryState();
        }), undefined);
}

var $$URL = {
  baseSlug: baseSlug,
  toList: toList,
  toString: toString,
  match: match,
  redirect: redirect
};

var convertFragment = ClaimTransferInvitationModelDeref_frag_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClaimTransferInvitationModelDeref_frag_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClaimTransferInvitationModelDeref_frag_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ClaimTransferInvitationModelDeref_frag_graphql$Thin.node, convertFragment, fRef);
}

var Deref = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var Query = {
  Deref: Deref
};

var convertVariables = ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables, ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ClaimAnonymously_signUpErr_decode = ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.signUpErr_decode;

var ClaimAnonymously_signUpErr_fromString = ClaimTransferInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.signUpErr_fromString;

var ClaimAnonymously = {
  signUpErr_decode: ClaimAnonymously_signUpErr_decode,
  signUpErr_fromString: ClaimAnonymously_signUpErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use$1
};

var Mutation = {
  ClaimAnonymously: ClaimAnonymously
};

var newPassword = HopperState$Util.Observable.FormField.delegate(NewPasswordModel$Thin.newPassword, {
      read: (function (i) {
          return i.password;
        }),
      write: (function (i, password) {
          return {
                  claimProgress: i.claimProgress,
                  password: password,
                  email: i.email,
                  preferredLastName: i.preferredLastName,
                  docBirthdate: i.docBirthdate,
                  gatedFields: i.gatedFields
                };
        })
    });

var confirmNewPassword = HopperState$Util.Observable.FormField.delegate(NewPasswordModel$Thin.confirmNewPassword, {
      read: (function (i) {
          return i.password;
        }),
      write: (function (i, password) {
          return {
                  claimProgress: i.claimProgress,
                  password: password,
                  email: i.email,
                  preferredLastName: i.preferredLastName,
                  docBirthdate: i.docBirthdate,
                  gatedFields: i.gatedFields
                };
        })
    });

var email = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.email;
        }),
      write: (function (i, email) {
          return {
                  claimProgress: i.claimProgress,
                  password: i.password,
                  email: email,
                  preferredLastName: i.preferredLastName,
                  docBirthdate: i.docBirthdate,
                  gatedFields: i.gatedFields
                };
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var preferredLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredLastName;
        }),
      write: (function (pax, preferredLastName) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  email: pax.email,
                  preferredLastName: preferredLastName,
                  docBirthdate: pax.docBirthdate,
                  gatedFields: pax.gatedFields
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), (function (m, result) {
        var match = m.gatedFields;
        if (match.isLastNameGated && result.TAG === "Ok" && result._0 === undefined) {
          return {
                  TAG: "Error",
                  _0: "You must provide your last name in order to claim this account"
                };
        } else {
          return result;
        }
      }));

var docBirthdate = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docBirthdate;
        }),
      write: (function (pax, docBirthdate) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  email: pax.email,
                  preferredLastName: pax.preferredLastName,
                  docBirthdate: docBirthdate,
                  gatedFields: pax.gatedFields
                };
        })
    }, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), (function (m, result) {
        var match = m.gatedFields;
        if (match.isBirthdateGated && result.TAG === "Ok" && result._0 === undefined) {
          return {
                  TAG: "Error",
                  _0: "You must provide your birthdate in order to claim this account"
                };
        } else {
          return result;
        }
      }));

var blankModel_email = email.blankStore;

var blankModel_preferredLastName = preferredLastName.blankStore;

var blankModel_docBirthdate = docBirthdate.blankStore;

var blankModel_gatedFields = {
  hasAnyGatedFields: false,
  isBirthdateGated: false,
  isLastNameGated: false
};

var blankModel = {
  claimProgress: "NotAsked",
  password: NewPasswordModel$Thin.blankModel,
  email: blankModel_email,
  preferredLastName: blankModel_preferredLastName,
  docBirthdate: blankModel_docBirthdate,
  gatedFields: blankModel_gatedFields
};

function transfer(model, transfer$1) {
  return {
          claimProgress: model.claimProgress,
          password: model.password,
          email: model.email,
          preferredLastName: model.preferredLastName,
          docBirthdate: model.docBirthdate,
          gatedFields: transfer$1.gatedFields
        };
}

function validateClaimAnonymouslyInput(model, allegedToken) {
  var match = newPassword.validate(model, undefined);
  var match$1 = confirmNewPassword.validate(model, undefined);
  var match$2 = email.validate(model, undefined);
  var match$3 = preferredLastName.validate(model, undefined);
  var match$4 = docBirthdate.validate(model, undefined);
  if (match.TAG === "Ok" && match$1.TAG === "Ok" && match._0 === match$1._0) {
    if (match$2.TAG === "Ok" && match$2._0 !== undefined) {
      if (match$3.TAG === "Ok") {
        if (match$4.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    allegedToken: allegedToken,
                    newPasswd: match._0,
                    newEmail: Caml_option.valFromOption(match$2._0),
                    allegedLastname: Belt_Option.map(match$3._0, CS_NonemptyStrings$Util.NonEmptyString.toString),
                    allegedBirthdate: match$4._0
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "MissingBday"
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "MissingLName"
              };
      }
    } else {
      return {
              TAG: "Error",
              _0: "EmailError"
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "PasswordError"
          };
  }
}

var utils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), preferredLastName), docBirthdate);

function forceShowErrors(extra) {
  return Curry._2(utils.polyReducer, "MarkTouchedNow", extra);
}

function describeSignUpErr(enum_SignUpErr) {
  if ((enum_SignUpErr === "ACCOUNT_UPDATE_ERROR" || enum_SignUpErr === "INVITATION_NOT_FOUND" || enum_SignUpErr === "BOOKING_UPDATE_ERROR" || enum_SignUpErr === "USER_CANT_BE_CREATED_ASK_SUPPORT" || enum_SignUpErr === "EMAIL_ALREADY_IN_USE" || enum_SignUpErr === "CANNOT_CLAIM") && enum_SignUpErr === "EMAIL_ALREADY_IN_USE") {
    return "The email you have selected is already in use or cannot be used to complete your signup. Please contact support.";
  } else {
    return ClaimNewPaxInvitationModel$Thin.describeSignUpErr(enum_SignUpErr);
  }
}

var checkIsDirty = utils.checkIsDirty;

exports.$$URL = $$URL;
exports.Query = Query;
exports.Mutation = Mutation;
exports.newPassword = newPassword;
exports.confirmNewPassword = confirmNewPassword;
exports.email = email;
exports.preferredLastName = preferredLastName;
exports.docBirthdate = docBirthdate;
exports.blankModel = blankModel;
exports.transfer = transfer;
exports.validateClaimAnonymouslyInput = validateClaimAnonymouslyInput;
exports.checkIsDirty = checkIsDirty;
exports.utils = utils;
exports.forceShowErrors = forceShowErrors;
exports.describeSignUpErr = describeSignUpErr;
/* commitMutation Not a pure module */
