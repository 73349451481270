// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var JustAnError$Thin = require("../pages/JustAnError.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var VerifyEmailPage$Thin = require("../pages/VerifyEmailPage.bs.js");
var ClaimNewPaxInvitationModel$Thin = require("../utils/accounts/ClaimNewPaxInvitationModel.bs.js");
var ClaimTransferInvitationModel$Thin = require("../utils/accounts/ClaimTransferInvitationModel.bs.js");
var ClaimPrefilledInvitationModel$Thin = require("../utils/accounts/ClaimPrefilledInvitationModel.bs.js");

function checkRoute(qs) {
  var token = qs.token;
  var tmp;
  var exit = 0;
  var millisTS = qs.expiryMillis;
  if (millisTS !== undefined && Date.now() > millisTS) {
    tmp = {
      TAG: "Error",
      _0: "ExpiredLink"
    };
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (qs.landing !== undefined) {
      if (token !== undefined) {
        var token$1 = Caml_option.valFromOption(token);
        switch (qs.landing) {
          case "claim_account_transfer" :
              tmp = qs.expiryMillis !== undefined ? ({
                    TAG: "Ok",
                    _0: {
                      TAG: "FireEffect",
                      _0: (function () {
                          ClaimTransferInvitationModel$Thin.$$URL.redirect(token$1);
                        })
                    }
                  }) : ({
                    TAG: "Error",
                    _0: "UnsupportedCombo"
                  });
              break;
          case "claim_new_passenger_invitation" :
              tmp = qs.expiryMillis !== undefined ? ({
                    TAG: "Ok",
                    _0: {
                      TAG: "FireEffect",
                      _0: (function () {
                          ClaimNewPaxInvitationModel$Thin.$$URL.redirect([
                                token$1,
                                "SelectClaimMethod"
                              ]);
                        })
                    }
                  }) : ({
                    TAG: "Error",
                    _0: "UnsupportedCombo"
                  });
              break;
          case "claim_prefilled_account_invitation" :
              tmp = qs.expiryMillis !== undefined ? ({
                    TAG: "Ok",
                    _0: {
                      TAG: "FireEffect",
                      _0: (function () {
                          ClaimPrefilledInvitationModel$Thin.$$URL.redirect(token$1);
                        })
                    }
                  }) : ({
                    TAG: "Error",
                    _0: "UnsupportedCombo"
                  });
              break;
          case "verify_email" :
              tmp = qs.expiryMillis !== undefined ? ({
                    TAG: "Ok",
                    _0: {
                      TAG: "RenderRoutelessPage",
                      _0: (function () {
                          return JsxRuntime.jsx(VerifyEmailPage$Thin.make, {
                                      withToken: token$1
                                    });
                        })
                    }
                  }) : ({
                    TAG: "Error",
                    _0: "UnsupportedCombo"
                  });
              break;
          default:
            tmp = {
              TAG: "Error",
              _0: "UnsupportedCombo"
            };
        }
      } else {
        tmp = {
          TAG: "Error",
          _0: "UnsupportedCombo"
        };
      }
    } else {
      tmp = {
        TAG: "Error",
        _0: "NoLanding"
      };
    }
  }
  return [
          qs,
          tmp
        ];
}

function errorMsg(e) {
  switch (e) {
    case "ExpiredLink" :
        return "This link is expired.";
    case "NoLanding" :
    case "UnsupportedCombo" :
        return "Uh oh! We don't know how to handle this kind of link. Make sure you've clicked the link as it was provided to you or fully copy and pasted the entirety of the link. Be sure the link you used is the entire link.";
    
  }
}

function DeeplinkRoute(props) {
  var match = HopperURL$Thin.Context.useQueryStateProjection(checkRoute, "PassThru", undefined);
  var route = match[1];
  React.useEffect((function () {
          var fx;
          var exit = 0;
          if (route.TAG === "Ok") {
            var fx$1 = route._0;
            if (fx$1.TAG === "FireEffect") {
              fx = fx$1._0;
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            fx = (function () {
                
              });
          }
          fx();
        }), [JSON.stringify(match[0])]);
  if (route.TAG !== "Ok") {
    return JsxRuntime.jsx(StandardLayout$Thin.make, {
                children: JsxRuntime.jsx(JustAnError$Thin.make, {
                      text: errorMsg(route._0)
                    })
              });
  }
  var component = route._0;
  if (component.TAG === "FireEffect") {
    return null;
  } else {
    return JsxRuntime.jsx(StandardLayout$Thin.make, {
                children: component._0()
              });
  }
}

var make = DeeplinkRoute;

exports.checkRoute = checkRoute;
exports.errorMsg = errorMsg;
exports.make = make;
/* react Not a pure module */
