// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Merge$Util = require("util/src/Merge.bs.js");
var AppLink$Thin = require("../components/common/atoms/AppLink.bs.js");
var JsxRuntime = require("react/jsx-runtime");

var account_parseSteps = [];

var account = {
  desc: "Account link in navbar and side bar",
  value: "My Account",
  parseSteps: account_parseSteps
};

var bookings_parseSteps = [];

var bookings = {
  desc: "Bookings link in navbar and side bar",
  value: "Bookings",
  parseSteps: bookings_parseSteps
};

var login_parseSteps = [];

var login = {
  desc: "Login link in navbar and side bar",
  value: "Login",
  parseSteps: login_parseSteps
};

var logout_parseSteps = [];

var logout = {
  desc: "Logout link in navbar and side bar",
  value: "Logout",
  parseSteps: logout_parseSteps
};

var connectedAccounts_parseSteps = [];

var connectedAccounts = {
  desc: "Connected accounts link in navbar and side bar",
  value: "Connected Accounts",
  parseSteps: connectedAccounts_parseSteps
};

var Nav = {
  account: account,
  bookings: bookings,
  login: login,
  logout: logout,
  connectedAccounts: connectedAccounts
};

var heading_parseSteps = [];

var heading = {
  desc: "Heading text on login page",
  value: "Welcome back",
  parseSteps: heading_parseSteps
};

var subHeading_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("SignUpLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: ctx.signUpLink,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var subHeading = {
  desc: "Subheading text on login page",
  value: "First time here? <SignUpLink>Sign up!</SignUpLink>",
  parseSteps: subHeading_parseSteps
};

var emailPlaceholder_parseSteps = [];

var emailPlaceholder = {
  desc: "Email placeholder text on login page",
  value: "Your email",
  parseSteps: emailPlaceholder_parseSteps
};

var passwordPlaceholder_parseSteps = [];

var passwordPlaceholder = {
  desc: "Password placeholder text on login page",
  value: "Your password",
  parseSteps: passwordPlaceholder_parseSteps
};

var login_parseSteps$1 = [];

var login$1 = {
  desc: "Login button text on login page",
  value: "Login",
  parseSteps: login_parseSteps$1
};

var forgotPassword_parseSteps = [];

var forgotPassword = {
  desc: "Forgot password text on login page",
  value: "Forgot password?",
  parseSteps: forgotPassword_parseSteps
};

var noUserError_parseSteps = [];

var noUserError = {
  desc: "Error message when user does not exist",
  value: "No user found with that email and password.",
  parseSteps: noUserError_parseSteps
};

var Login = {
  heading: heading,
  subHeading: subHeading,
  emailPlaceholder: emailPlaceholder,
  passwordPlaceholder: passwordPlaceholder,
  login: login$1,
  forgotPassword: forgotPassword,
  noUserError: noUserError
};

var heading_parseSteps$1 = [];

var heading$1 = {
  desc: "Heading text on claim page",
  value: "Claim your invitation",
  parseSteps: heading_parseSteps$1
};

var subheading_parseSteps = [];

var subheading = {
  desc: "Subheading text on claim page",
  value: "You've been invited to join the team! Please set your password below.",
  parseSteps: subheading_parseSteps
};

var passwordPlaceholder_parseSteps$1 = [];

var passwordPlaceholder$1 = {
  desc: "Password placeholder text on claim page",
  value: "Your password",
  parseSteps: passwordPlaceholder_parseSteps$1
};

var confirmPasswordPlaceholder_parseSteps = [];

var confirmPasswordPlaceholder = {
  desc: "Confirm password placeholder text on claim page",
  value: "Confirm password",
  parseSteps: confirmPasswordPlaceholder_parseSteps
};

var claim_parseSteps = [];

var claim = {
  desc: "Claim button text on claim page",
  value: "Claim your invitation",
  parseSteps: claim_parseSteps
};

var passwordSetError_parseSteps = [];

var passwordSetError = {
  desc: "Error message when password set fails",
  value: "Something went wrong setting your password. Please try again.",
  parseSteps: passwordSetError_parseSteps
};

var expired_parseSteps = [];

var expired = {
  desc: "Error message when invitation has expired",
  value: "Your invitation has expired!",
  parseSteps: expired_parseSteps
};

var success_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: ctx.loginLink,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var success = {
  desc: "Success message when invitation is claimed",
  value: "You're good to go! Please <LoginLink>login</LoginLink>.",
  parseSteps: success_parseSteps
};

var Claim = {
  heading: heading$1,
  subheading: subheading,
  passwordPlaceholder: passwordPlaceholder$1,
  confirmPasswordPlaceholder: confirmPasswordPlaceholder,
  claim: claim,
  passwordSetError: passwordSetError,
  expired: expired,
  success: success
};

var heading_parseSteps$2 = [];

var heading$2 = {
  desc: "Heading text on verify page",
  value: "Verify your email",
  parseSteps: heading_parseSteps$2
};

var loading_parseSteps = [];

var loading = {
  desc: "Loading text on verify page",
  value: "We're verifying your email! Hold tight!",
  parseSteps: loading_parseSteps
};

var expired_parseSteps$1 = [];

var expired$1 = {
  desc: "Error message when verification link has expired",
  value: "Your verification link has expired! Please try again!",
  parseSteps: expired_parseSteps$1
};

var successWithRedirect_parseSteps = [];

var successWithRedirect = {
  desc: "Success message when verification is successful and we want to redirect to the account page",
  value: "Your email has been verified! You will now be redirected to your account page.",
  parseSteps: successWithRedirect_parseSteps
};

var successNoRedirect_parseSteps = [];

var successNoRedirect = {
  desc: "Success message when verification is successful and we do not want to redirect",
  value: "Your email has been verified! You may now close this window.",
  parseSteps: successNoRedirect_parseSteps
};

var verifyError_parseSteps = [];

var verifyError = {
  desc: "Error message when verification fails",
  value: "Something went wrong verifying your email. Please try again.",
  parseSteps: verifyError_parseSteps
};

var Verify = {
  heading: heading$2,
  loading: loading,
  expired: expired$1,
  successWithRedirect: successWithRedirect,
  successNoRedirect: successNoRedirect,
  verifyError: verifyError
};

var heading_parseSteps$3 = [];

var heading$3 = {
  desc: "Heading text on reset password page",
  value: "Reset your password",
  parseSteps: heading_parseSteps$3
};

var passwordPlaceholder_parseSteps$2 = [];

var passwordPlaceholder$2 = {
  desc: "Password placeholder text on reset password page",
  value: "Your new password",
  parseSteps: passwordPlaceholder_parseSteps$2
};

var confirmPasswordPlaceholder_parseSteps$1 = [];

var confirmPasswordPlaceholder$1 = {
  desc: "Confirm password placeholder text on reset password page",
  value: "Confirm new password",
  parseSteps: confirmPasswordPlaceholder_parseSteps$1
};

var emailPlaceholder_parseSteps$1 = [];

var emailPlaceholder$1 = {
  desc: "Email placeholder text on reset password page",
  value: "Your email",
  parseSteps: emailPlaceholder_parseSteps$1
};

var reset_parseSteps = [];

var reset = {
  desc: "Reset button text on reset password page",
  value: "Reset password",
  parseSteps: reset_parseSteps
};

var expired_parseSteps$2 = [];

var expired$2 = {
  desc: "Error message when reset password link has expired",
  value: "Your reset password link has expired! Please try again!",
  parseSteps: expired_parseSteps$2
};

var passwordUpdated_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: ctx.loginLink,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var passwordUpdated = {
  desc: "Success message when password is updated",
  value: "Your password has been updated! Please <LoginLink>login</LoginLink>.",
  parseSteps: passwordUpdated_parseSteps
};

var sendResetEmail_parseSteps = [];

var sendResetEmail = {
  desc: "Send reset password email button text on reset password page",
  value: "Send a reset link",
  parseSteps: sendResetEmail_parseSteps
};

var emailSent_parseSteps = [];

var emailSent = {
  desc: "Success message when reset password email is sent",
  value: "If your account exists, you will soon receive an email with a reset link! If it doesn't arrive in a few minutes, ",
  parseSteps: emailSent_parseSteps
};

var resendEmail_parseSteps = [];

var resendEmail = {
  desc: "Text on resend link after reset password email is sent",
  value: "click here to resend.",
  parseSteps: resendEmail_parseSteps
};

var resetError_parseSteps = [];

var resetError = {
  desc: "Error message when reset password fails",
  value: "Something went wrong resetting your password. Please try again.",
  parseSteps: resetError_parseSteps
};

var resetLinkError_parseSteps = [];

var resetLinkError = {
  desc: "Error message when reset password link email fails",
  value: "A reset password email could not be sent. Please try again.",
  parseSteps: resetLinkError_parseSteps
};

var passwordMismatch_parseSteps = [];

var passwordMismatch = {
  desc: "Error message passwords do not match",
  value: "Passwords do not match!",
  parseSteps: passwordMismatch_parseSteps
};

var ResetPassword = {
  heading: heading$3,
  passwordPlaceholder: passwordPlaceholder$2,
  confirmPasswordPlaceholder: confirmPasswordPlaceholder$1,
  emailPlaceholder: emailPlaceholder$1,
  reset: reset,
  expired: expired$2,
  passwordUpdated: passwordUpdated,
  sendResetEmail: sendResetEmail,
  emailSent: emailSent,
  resendEmail: resendEmail,
  resetError: resetError,
  resetLinkError: resetLinkError,
  passwordMismatch: passwordMismatch
};

var heading_parseSteps$4 = [];

var heading$4 = {
  desc: "Heading text on account page",
  value: "My account",
  parseSteps: heading_parseSteps$4
};

var subheading_parseSteps$1 = [];

var subheading$1 = {
  desc: "Subheading text on account page",
  value: "Manage your account information below.",
  parseSteps: subheading_parseSteps$1
};

var noProfileFound_parseSteps = [];

var noProfileFound = {
  desc: "Error message when no profile is found",
  value: "No profile found.",
  parseSteps: noProfileFound_parseSteps
};

var accessHeading_parseSteps = [];

var accessHeading = {
  desc: "Heading text on account access section of account page",
  value: "Account access",
  parseSteps: accessHeading_parseSteps
};

var noEmail_parseSteps = [];

var noEmail = {
  desc: "Error message when no email is found",
  value: "No email address associated with this account.",
  parseSteps: noEmail_parseSteps
};

var emailLabel_parseSteps = [];

var emailLabel = {
  desc: "Label text for email address on account page",
  value: "Email",
  parseSteps: emailLabel_parseSteps
};

var changeEmail_parseSteps = [];

var changeEmail = {
  desc: "Change email button text on account page",
  value: "Change email?",
  parseSteps: changeEmail_parseSteps
};

var emailModalHeading_parseSteps = [];

var emailModalHeading = {
  desc: "Heading text on change email modal",
  value: "Change email",
  parseSteps: emailModalHeading_parseSteps
};

var emailModalDescription_parseSteps = [];

var emailModalDescription = {
  desc: "Description text on change email modal",
  value: "Change the email address associated with your account.",
  parseSteps: emailModalDescription_parseSteps
};

var emailVerify_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("b", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: mustache(value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var emailVerify = {
  desc: "Text on change email modal when email is changed",
  value: "Please check inbox for <b>{{userEmail}}</b> to verify your new email address!",
  parseSteps: emailVerify_parseSteps
};

var emailModalEmailPlaceholder_parseSteps = [];

var emailModalEmailPlaceholder = {
  desc: "Email placeholder text on change email modal",
  value: "Your new email address",
  parseSteps: emailModalEmailPlaceholder_parseSteps
};

var emailModalChangeButton_parseSteps = [];

var emailModalChangeButton = {
  desc: "Change email button text on change email modal",
  value: "Change email",
  parseSteps: emailModalChangeButton_parseSteps
};

var emailModalError_parseSteps = [];

var emailModalError = {
  desc: "Error message when change email fails",
  value: "Something went wrong changing your email. Please try again.",
  parseSteps: emailModalError_parseSteps
};

var changePassword_parseSteps = [];

var changePassword = {
  desc: "Change password button text on account page",
  value: "Change password?",
  parseSteps: changePassword_parseSteps
};

var passwordLabel_parseSteps = [];

var passwordLabel = {
  desc: "Label text for password on account page",
  value: "Password",
  parseSteps: passwordLabel_parseSteps
};

var passwordModalHeading_parseSteps = [];

var passwordModalHeading = {
  desc: "Heading text on change password modal",
  value: "Change password",
  parseSteps: passwordModalHeading_parseSteps
};

var passwordModalDescription_parseSteps = [];

var passwordModalDescription = {
  desc: "Description text on change password modal",
  value: "Change your account password.",
  parseSteps: passwordModalDescription_parseSteps
};

var passwordModalCurrentPasswordPlaceholder_parseSteps = [];

var passwordModalCurrentPasswordPlaceholder = {
  desc: "Current password placeholder text on change password modal",
  value: "Your current password",
  parseSteps: passwordModalCurrentPasswordPlaceholder_parseSteps
};

var passwordModalNewPasswordPlaceholder_parseSteps = [];

var passwordModalNewPasswordPlaceholder = {
  desc: "New password placeholder text on change password modal",
  value: "Your new password",
  parseSteps: passwordModalNewPasswordPlaceholder_parseSteps
};

var passwordModalConfirmPasswordPlaceholder_parseSteps = [];

var passwordModalConfirmPasswordPlaceholder = {
  desc: "Confirm password placeholder text on change password modal",
  value: "Confirm new password",
  parseSteps: passwordModalConfirmPasswordPlaceholder_parseSteps
};

var passwordModalChangeButton_parseSteps = [];

var passwordModalChangeButton = {
  desc: "Change password button text on change password modal",
  value: "Change password",
  parseSteps: passwordModalChangeButton_parseSteps
};

var passwordModalError_parseSteps = [];

var passwordModalError = {
  desc: "Error message when change password fails",
  value: "Something went wrong changing your password. Please try again.",
  parseSteps: passwordModalError_parseSteps
};

var passwordModalSuccess_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: ctx.loginLink,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var passwordModalSuccess = {
  desc: "Success message when password is changed",
  value: "Your password has been changed! You will now be redirected to the <LoginLink>login page</LoginLink>.",
  parseSteps: passwordModalSuccess_parseSteps
};

var personalInformationHeading_parseSteps = [];

var personalInformationHeading = {
  desc: "Heading text on personal information section of account page",
  value: "Personal information",
  parseSteps: personalInformationHeading_parseSteps
};

var preferredFirstNameLabel_parseSteps = [];

var preferredFirstNameLabel = {
  desc: "Label text for preferred first name on account page",
  value: "First Name (preferred)",
  parseSteps: preferredFirstNameLabel_parseSteps
};

var preferredFirstNamePlaceholder_parseSteps = [];

var preferredFirstNamePlaceholder = {
  desc: "Preferred first name placeholder text on account page",
  value: "First Name (preferred)",
  parseSteps: preferredFirstNamePlaceholder_parseSteps
};

var preferredFirstNameHelpText_parseSteps = [];

var preferredFirstNameHelpText = {
  desc: "Help text for preferred first name on account page",
  value: "Your preferred first name",
  parseSteps: preferredFirstNameHelpText_parseSteps
};

var preferredLastNameLabel_parseSteps = [];

var preferredLastNameLabel = {
  desc: "Label text for preferred last name on account page",
  value: "Last Name (preferred)",
  parseSteps: preferredLastNameLabel_parseSteps
};

var preferredLastNamePlaceholder_parseSteps = [];

var preferredLastNamePlaceholder = {
  desc: "Preferred last name placeholder text on account page",
  value: "Last Name (preferred)",
  parseSteps: preferredLastNamePlaceholder_parseSteps
};

var preferredLastNameHelpText_parseSteps = [];

var preferredLastNameHelpText = {
  desc: "Help text for preferred last name on account page",
  value: "Your preferred last name",
  parseSteps: preferredLastNameHelpText_parseSteps
};

var legalFirstNameLabel_parseSteps = [];

var legalFirstNameLabel = {
  desc: "Label text for legal first name on account page",
  value: "First Name (travel document)",
  parseSteps: legalFirstNameLabel_parseSteps
};

var legalFirstNamePlaceholder_parseSteps = [];

var legalFirstNamePlaceholder = {
  desc: "Legal first name placeholder text on account page",
  value: "First Name (travel document)",
  parseSteps: legalFirstNamePlaceholder_parseSteps
};

var legalFirstNameHelpText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var legalFirstNameHelpText = {
  desc: "Help text for legal first name on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: legalFirstNameHelpText_parseSteps
};

var legalLastNameLabel_parseSteps = [];

var legalLastNameLabel = {
  desc: "Label text for legal last name on account page",
  value: "Last Name (travel document)",
  parseSteps: legalLastNameLabel_parseSteps
};

var legalLastNamePlaceholder_parseSteps = [];

var legalLastNamePlaceholder = {
  desc: "Legal last name placeholder text on account page",
  value: "Last Name (travel document)",
  parseSteps: legalLastNamePlaceholder_parseSteps
};

var legalLastNameHelpText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var legalLastNameHelpText = {
  desc: "Help text for legal last name on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: legalLastNameHelpText_parseSteps
};

var birthdateLabel_parseSteps = [];

var birthdateLabel = {
  desc: "Label text for birthdate on account page",
  value: "Birthdate",
  parseSteps: birthdateLabel_parseSteps
};

var birthdateHelpText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var birthdateHelpText = {
  desc: "Help text for birthdate on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: birthdateHelpText_parseSteps
};

var citizenshipLabel_parseSteps = [];

var citizenshipLabel = {
  desc: "Label text for citizenship on account page",
  value: "Country of Citizenship",
  parseSteps: citizenshipLabel_parseSteps
};

var citizenshipPlaceholder_parseSteps = [];

var citizenshipPlaceholder = {
  desc: "Placeholder text for citizenship on account page",
  value: "Select a country",
  parseSteps: citizenshipPlaceholder_parseSteps
};

var citizenshipHelpText_parseSteps = [];

var citizenshipHelpText = {
  desc: "Help text for citizenship on account page",
  value: "",
  parseSteps: citizenshipHelpText_parseSteps
};

var genderLabel_parseSteps = [];

var genderLabel = {
  desc: "Label text for gender on account page",
  value: "Gender (travel document)",
  parseSteps: genderLabel_parseSteps
};

var genderPlaceholder_parseSteps = [];

var genderPlaceholder = {
  desc: "Placeholder text for gender on account page",
  value: "Select a gender",
  parseSteps: genderPlaceholder_parseSteps
};

var genderHelpText_parseSteps = [];

var genderHelpText = {
  desc: "Help text for gender on account page",
  value: "",
  parseSteps: genderHelpText_parseSteps
};

var modifyProfileButton_parseSteps = [];

var modifyProfileButton = {
  desc: "Modify profile button text on account page",
  value: "Modify your profile",
  parseSteps: modifyProfileButton_parseSteps
};

var saveButton_parseSteps = [];

var saveButton = {
  desc: "Save button text on account page",
  value: "Save",
  parseSteps: saveButton_parseSteps
};

var cancelButton_parseSteps = [];

var cancelButton = {
  desc: "Cancel button text on account page",
  value: "Cancel",
  parseSteps: cancelButton_parseSteps
};

var pleaseVerifyYourPrefilledAccount_parseSteps = [];

var pleaseVerifyYourPrefilledAccount = {
  desc: "Text telling you to verify the account data you entered for your prefilled account to which you have just accepted an invitation.",
  value: "Thank you for claiming your account! Please check your information on this page for accuracy. We've also sent you an email with instructions to verify your email address. Some actions are blocked until you do so.",
  parseSteps: pleaseVerifyYourPrefilledAccount_parseSteps
};

var pleaseVerifyYourTransferAccountNoEmail_parseSteps = [];

var pleaseVerifyYourTransferAccountNoEmail = {
  desc: "Text telling you to verify the account data you entered for your transferred account to which you have just accepted an invitation.",
  value: "Thank you for claiming your account! Please check your information on this page for accuracy.",
  parseSteps: pleaseVerifyYourTransferAccountNoEmail_parseSteps
};

var Account = {
  heading: heading$4,
  subheading: subheading$1,
  noProfileFound: noProfileFound,
  accessHeading: accessHeading,
  noEmail: noEmail,
  emailLabel: emailLabel,
  changeEmail: changeEmail,
  emailModalHeading: emailModalHeading,
  emailModalDescription: emailModalDescription,
  emailVerify: emailVerify,
  emailModalEmailPlaceholder: emailModalEmailPlaceholder,
  emailModalChangeButton: emailModalChangeButton,
  emailModalError: emailModalError,
  changePassword: changePassword,
  passwordLabel: passwordLabel,
  passwordModalHeading: passwordModalHeading,
  passwordModalDescription: passwordModalDescription,
  passwordModalCurrentPasswordPlaceholder: passwordModalCurrentPasswordPlaceholder,
  passwordModalNewPasswordPlaceholder: passwordModalNewPasswordPlaceholder,
  passwordModalConfirmPasswordPlaceholder: passwordModalConfirmPasswordPlaceholder,
  passwordModalChangeButton: passwordModalChangeButton,
  passwordModalError: passwordModalError,
  passwordModalSuccess: passwordModalSuccess,
  personalInformationHeading: personalInformationHeading,
  preferredFirstNameLabel: preferredFirstNameLabel,
  preferredFirstNamePlaceholder: preferredFirstNamePlaceholder,
  preferredFirstNameHelpText: preferredFirstNameHelpText,
  preferredLastNameLabel: preferredLastNameLabel,
  preferredLastNamePlaceholder: preferredLastNamePlaceholder,
  preferredLastNameHelpText: preferredLastNameHelpText,
  legalFirstNameLabel: legalFirstNameLabel,
  legalFirstNamePlaceholder: legalFirstNamePlaceholder,
  legalFirstNameHelpText: legalFirstNameHelpText,
  legalLastNameLabel: legalLastNameLabel,
  legalLastNamePlaceholder: legalLastNamePlaceholder,
  legalLastNameHelpText: legalLastNameHelpText,
  birthdateLabel: birthdateLabel,
  birthdateHelpText: birthdateHelpText,
  citizenshipLabel: citizenshipLabel,
  citizenshipPlaceholder: citizenshipPlaceholder,
  citizenshipHelpText: citizenshipHelpText,
  genderLabel: genderLabel,
  genderPlaceholder: genderPlaceholder,
  genderHelpText: genderHelpText,
  modifyProfileButton: modifyProfileButton,
  saveButton: saveButton,
  cancelButton: cancelButton,
  pleaseVerifyYourPrefilledAccount: pleaseVerifyYourPrefilledAccount,
  pleaseVerifyYourTransferAccountNoEmail: pleaseVerifyYourTransferAccountNoEmail
};

var upcomingHeading_parseSteps = [];

var upcomingHeading = {
  desc: "Heading text on upcoming bookings section of bookings page",
  value: "Upcoming bookings",
  parseSteps: upcomingHeading_parseSteps
};

var noUpcomingText_parseSteps = [];

var noUpcomingText = {
  desc: "Text when there are no upcoming bookings",
  value: "No upcoming bookings",
  parseSteps: noUpcomingText_parseSteps
};

var pastHeading_parseSteps = [];

var pastHeading = {
  desc: "Heading text on past bookings section of bookings page",
  value: "Booking history",
  parseSteps: pastHeading_parseSteps
};

var youWereAddedToABookingSuccess_parseSteps = [];

var youWereAddedToABookingSuccess = {
  desc: "Success message we show you when you were succesfully added to a booking",
  value: "You were added to an upcoming booking! You can see your upcoming bookings below.",
  parseSteps: youWereAddedToABookingSuccess_parseSteps
};

var Bookings = {
  upcomingHeading: upcomingHeading,
  noUpcomingText: noUpcomingText,
  pastHeading: pastHeading,
  youWereAddedToABookingSuccess: youWereAddedToABookingSuccess
};

var emailVerify_parseSteps$1 = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("b", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: mustache(value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var emailVerify$1 = {
  desc: "Text on change email modal when email is changed",
  value: "Please check inbox for <b>{{userEmail}}</b> to verify your new email address! You must verify before taking any actions on this page.",
  parseSteps: emailVerify_parseSteps$1
};

var heroLinksViewMore_parseSteps = [];

var heroLinksViewMore = {
  desc: "View more links on booking details hero",
  value: "View more",
  parseSteps: heroLinksViewMore_parseSteps
};

var heroLinksViewFewer_parseSteps = [];

var heroLinksViewFewer = {
  desc: "View fewer links on booking details hero",
  value: "View fewer",
  parseSteps: heroLinksViewFewer_parseSteps
};

var stateroomDetailsHeading_parseSteps = [];

var stateroomDetailsHeading = {
  desc: "Heading text on stateroom details section of booking details page",
  value: "Stateroom Details",
  parseSteps: stateroomDetailsHeading_parseSteps
};

var cabinDeckChipText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("DeckNumber", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: mustache(value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var cabinDeckChipText = {
  desc: "Text for cabin deck chip on booking details page",
  value: "Deck <DeckNumber>{{cabinDeckNumber}}</DeckNumber>",
  parseSteps: cabinDeckChipText_parseSteps
};

var cabinNumberChipText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("CabinNumber", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: mustache(value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var cabinNumberChipText = {
  desc: "Text for cabin number chip on booking details page",
  value: "Cabin <CabinNumber>{{cabinNumber}}</CabinNumber>",
  parseSteps: cabinNumberChipText_parseSteps
};

var berthsChipText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("NumBerths", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: mustache(value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var berthsChipText = {
  desc: "Text for berth number chip on booking details page",
  value: "<NumBerths>{{numBerths}}</NumBerths> Berth{{#berthsPlural}}s{{/berthsPlural}}",
  parseSteps: berthsChipText_parseSteps
};

var billingContactHeading_parseSteps = [];

var billingContactHeading = {
  desc: "Heading text on billing contact section of booking details page",
  value: "Billing Contact",
  parseSteps: billingContactHeading_parseSteps
};

var manageReservation_parseSteps = [];

var manageReservation = {
  desc: "Dropdown button text for manage reservation actions",
  value: "Manage Reservation",
  parseSteps: manageReservation_parseSteps
};

var upgradeBooking_parseSteps = [];

var upgradeBooking = {
  desc: "Dropdown button text for upgrade booking action",
  value: "Upgrade",
  parseSteps: upgradeBooking_parseSteps
};

var downgradeBooking_parseSteps = [];

var downgradeBooking = {
  desc: "Dropdown button text for downgrade booking action",
  value: "Downgrade",
  parseSteps: downgradeBooking_parseSteps
};

var changeCabin_parseSteps = [];

var changeCabin = {
  desc: "Dropdown button text for change cabin action",
  value: "Change Cabin",
  parseSteps: changeCabin_parseSteps
};

var cancelBooking_parseSteps = [];

var cancelBooking = {
  desc: "Dropdown button text for cancel booking action",
  value: "Cancel",
  parseSteps: cancelBooking_parseSteps
};

var billingSummaryHeading_parseSteps = [];

var billingSummaryHeading = {
  desc: "Heading text on billing summary section of booking details page",
  value: "Billing Summary",
  parseSteps: billingSummaryHeading_parseSteps
};

var noBillingSummary_parseSteps = [];

var noBillingSummary = {
  desc: "Text when there is no billing summary",
  value: "No billing summary available",
  parseSteps: noBillingSummary_parseSteps
};

var viewBillingDetails_parseSteps = [];

var viewBillingDetails = {
  desc: "Text for view billing details button",
  value: "View details",
  parseSteps: viewBillingDetails_parseSteps
};

var totalBilled_parseSteps = [];

var totalBilled = {
  desc: "Text for total billed on billing summary section of booking details page",
  value: "Total Billed",
  parseSteps: totalBilled_parseSteps
};

var totalPaid_parseSteps = [];

var totalPaid = {
  desc: "Text for total paid on billing summary section of booking details page",
  value: "Total Paid",
  parseSteps: totalPaid_parseSteps
};

var remainingBalance_parseSteps = [];

var remainingBalance = {
  desc: "Text for remaining balance on billing summary and invoice drawer sections of booking details page",
  value: "Remaining balance",
  parseSteps: remainingBalance_parseSteps
};

var pendingPayment_parseSteps = [];

var pendingPayment = {
  desc: "Text for pending payment on billing summary and invoice drawer sections of booking details page",
  value: "Pending payment",
  parseSteps: pendingPayment_parseSteps
};

var balanceAfterPending_parseSteps = [];

var balanceAfterPending = {
  desc: "Text for total after pending on billing summary and invoice drawer sections of booking details page",
  value: "Balance after pending",
  parseSteps: balanceAfterPending_parseSteps
};

var makeAPayment_parseSteps = [];

var makeAPayment = {
  desc: "Text for make a payment button on billing summary section of booking details page",
  value: "Make a payment",
  parseSteps: makeAPayment_parseSteps
};

var billingAndPaymentsTitle_parseSteps = [];

var billingAndPaymentsTitle = {
  desc: "Title text for payment details / invoice section of booking details page",
  value: "Billing & Payments",
  parseSteps: billingAndPaymentsTitle_parseSteps
};

var depositScheduleTitle_parseSteps = [];

var depositScheduleTitle = {
  desc: "Title text for payment details / invoice section of booking details page",
  value: "Deposit Schedule",
  parseSteps: depositScheduleTitle_parseSteps
};

var invoiceTitle_parseSteps = [];

var invoiceTitle = {
  desc: "Title text for payment details / invoice section of booking details page",
  value: "Invoice",
  parseSteps: invoiceTitle_parseSteps
};

var bookingChargesHeading_parseSteps = [];

var bookingChargesHeading = {
  desc: "Heading text on booking charges section of booking details page",
  value: "Booking Charges",
  parseSteps: bookingChargesHeading_parseSteps
};

var paymentsHeading_parseSteps = [];

var paymentsHeading = {
  desc: "Heading text on payment section of booking details page",
  value: "Payments",
  parseSteps: paymentsHeading_parseSteps
};

var noBillingLines_parseSteps = [];

var noBillingLines = {
  desc: "Text when there are no billing lines",
  value: "No charges have been added yet",
  parseSteps: noBillingLines_parseSteps
};

var noBillingLinesInactive_parseSteps = [];

var noBillingLinesInactive = {
  desc: "Text when there are no billing lines on an inactive booking",
  value: "No charges added",
  parseSteps: noBillingLinesInactive_parseSteps
};

var noPaymentLines_parseSteps = [];

var noPaymentLines = {
  desc: "Text when there are no payemnt lines",
  value: "No payments have been made yet",
  parseSteps: noPaymentLines_parseSteps
};

var noPaymentLinesInactive_parseSteps = [];

var noPaymentLinesInactive = {
  desc: "Text when there are no payment lines on an inactive booking",
  value: "No payments made",
  parseSteps: noPaymentLinesInactive_parseSteps
};

var descriptionTableHeading_parseSteps = [];

var descriptionTableHeading = {
  desc: "Heading text on description column in invoice tables on booking details page",
  value: "Description",
  parseSteps: descriptionTableHeading_parseSteps
};

var amountTableHeading_parseSteps = [];

var amountTableHeading = {
  desc: "Heading text on amount column in invoice tables on booking details page",
  value: "Amount",
  parseSteps: amountTableHeading_parseSteps
};

var dateTableHeading_parseSteps = [];

var dateTableHeading = {
  desc: "Heading text on date column in invoice tables on booking details page",
  value: "Date",
  parseSteps: dateTableHeading_parseSteps
};

var payerTableHeading_parseSteps = [];

var payerTableHeading = {
  desc: "Heading text on payer column in invoice tables on booking details page",
  value: "Payer",
  parseSteps: payerTableHeading_parseSteps
};

var paymentMethodTableHeading_parseSteps = [];

var paymentMethodTableHeading = {
  desc: "Heading text on payment method column in invoice tables on booking details page",
  value: "Payment Method",
  parseSteps: paymentMethodTableHeading_parseSteps
};

var totalBilledTableFooter_parseSteps = [];

var totalBilledTableFooter = {
  desc: "Footer text on for total in billing invoice table on booking details page",
  value: "Total Billed",
  parseSteps: totalBilledTableFooter_parseSteps
};

var pendingBilledTableFooter_parseSteps = [];

var pendingBilledTableFooter = {
  desc: "Footer text on for pending total in billing invoice table on booking details page",
  value: "Pending Billed",
  parseSteps: pendingBilledTableFooter_parseSteps
};

var totalPaidTableFooter_parseSteps = [];

var totalPaidTableFooter = {
  desc: "Footer text on for total paid in payment invoice table on booking details page",
  value: "Total Paid",
  parseSteps: totalPaidTableFooter_parseSteps
};

var pendingPaidTableFooter_parseSteps = [];

var pendingPaidTableFooter = {
  desc: "Footer text on for pending payments in payment invoice table on booking details page",
  value: "Pending",
  parseSteps: pendingPaidTableFooter_parseSteps
};

var passengerListHeading_parseSteps = [];

var passengerListHeading = {
  desc: "Heading text on passenger list section of booking details page",
  value: "Passenger List",
  parseSteps: passengerListHeading_parseSteps
};

var addBerth_parseSteps = [];

var addBerth = {
  desc: "Text in passenger list heading for action to add berth",
  value: "Add Berth",
  parseSteps: addBerth_parseSteps
};

var maxOccReached_parseSteps = [];

var maxOccReached = {
  desc: "Text in passenger list Add Berth tooltip when max occupancy has been reached",
  value: "You've reached the maximum occupancy for this cabin. Please contact customer service for more options.",
  parseSteps: maxOccReached_parseSteps
};

var plTBA_parseSteps = [];

var plTBA = {
  desc: "Text in passenger list when that passenger is to be announced",
  value: "TBA",
  parseSteps: plTBA_parseSteps
};

var plStatus_parseSteps = [];

var plStatus = {
  desc: "Status heading text on passenger list table on booking details page",
  value: "Status",
  parseSteps: plStatus_parseSteps
};

var plName_parseSteps = [];

var plName = {
  desc: "Name heading text on passenger list table on booking details page",
  value: "Name",
  parseSteps: plName_parseSteps
};

var plBirthdate_parseSteps = [];

var plBirthdate = {
  desc: "Birthdate heading text on passenger list table on booking details page",
  value: "Birthdate",
  parseSteps: plBirthdate_parseSteps
};

var plEmail_parseSteps = [];

var plEmail = {
  desc: "Email heading text on passenger list table on booking details page",
  value: "Email",
  parseSteps: plEmail_parseSteps
};

var plConfirmed_parseSteps = [];

var plConfirmed = {
  desc: "Confirmed text within passenger list table on booking details page",
  value: "Confirmed",
  parseSteps: plConfirmed_parseSteps
};

var plInvited_parseSteps = [];

var plInvited = {
  desc: "Invited text within passenger list table on booking details page",
  value: "Invited",
  parseSteps: plInvited_parseSteps
};

var plIncomplete_parseSteps = [];

var plIncomplete = {
  desc: "Incomplete text within passenger list table on booking details page",
  value: "Incomplete",
  parseSteps: plIncomplete_parseSteps
};

var plAttachOnApply_parseSteps = [];

var plAttachOnApply = {
  desc: "Attach on apply text within passenger list table on booking details page",
  value: "Attach on Apply",
  parseSteps: plAttachOnApply_parseSteps
};

var plReplaceOnApply_parseSteps = [];

var plReplaceOnApply = {
  desc: "Replace on apply text within passenger list table on booking details page",
  value: "Replace on Apply",
  parseSteps: plReplaceOnApply_parseSteps
};

var plInviteOnApply_parseSteps = [];

var plInviteOnApply = {
  desc: "Invite on apply text within passenger list table on booking details page",
  value: "Invite on Apply",
  parseSteps: plInviteOnApply_parseSteps
};

var plUnknown_parseSteps = [];

var plUnknown = {
  desc: "Unknown text within passenger list table on booking details page",
  value: "Unknown",
  parseSteps: plUnknown_parseSteps
};

var addPassDrawerTitle_parseSteps = [];

var addPassDrawerTitle = {
  desc: "Title for add passenger drawer on booking details page",
  value: "Add Passenger",
  parseSteps: addPassDrawerTitle_parseSteps
};

var replacePassDrawerTitle_parseSteps = [];

var replacePassDrawerTitle = {
  desc: "Title for replace passenger drawer on booking details page",
  value: "Replace Passenger",
  parseSteps: replacePassDrawerTitle_parseSteps
};

var providePassengerDetails_parseSteps = [];

var providePassengerDetails = {
  desc: "Title in passenger list item menu for action to provide passenger details",
  value: "Provide Passenger Details",
  parseSteps: providePassengerDetails_parseSteps
};

var providePassengerDetailsDesc_parseSteps = [];

var providePassengerDetailsDesc = {
  desc: "Description in passenger list item menu for action to provide passenger details",
  value: "Enter passenger personal information to create a new Connected Account.",
  parseSteps: providePassengerDetailsDesc_parseSteps
};

var invitePassenger_parseSteps = [];

var invitePassenger = {
  desc: "Title in passenger list item menu for action to invite passenger",
  value: "Invite by Email",
  parseSteps: invitePassenger_parseSteps
};

var invitePassengerDesc_parseSteps = [];

var invitePassengerDesc = {
  desc: "Description in passenger list item menu for action to invite passenger",
  value: "Invite someone to join the booking. They can use an existing account or create a new one.",
  parseSteps: invitePassengerDesc_parseSteps
};

var selectFromManagedAccounts_parseSteps = [];

var selectFromManagedAccounts = {
  desc: "Title in passenger list item menu for action to select from managed accounts",
  value: "Select from Accounts I Manage",
  parseSteps: selectFromManagedAccounts_parseSteps
};

var selectFromManagedAccountsDesc_parseSteps = [];

var selectFromManagedAccountsDesc = {
  desc: "Description in passenger list item menu for action to select from managed accounts",
  value: "Select yourself or a Connected Account you control.",
  parseSteps: selectFromManagedAccountsDesc_parseSteps
};

var addPassengerByLoyalty_parseSteps = [];

var addPassengerByLoyalty = {
  desc: "Title in passenger list item menu for action to add by brand family loyalty number",
  value: "Add by Loyalty Number",
  parseSteps: addPassengerByLoyalty_parseSteps
};

var addPassengerByLoyaltyDesc_parseSteps = [];

var addPassengerByLoyaltyDesc = {
  desc: "Description in passener list item menu for action to add by brand family loyalty number",
  value: "Add a passenger by entering their {{brandFamilyName}} loyalty number.",
  parseSteps: addPassengerByLoyaltyDesc_parseSteps
};

var editDetails_parseSteps = [];

var editDetails = {
  desc: "Text in passenger list item menu for action to edit passenger details",
  value: "Edit Details",
  parseSteps: editDetails_parseSteps
};

var removeMyself_parseSteps = [];

var removeMyself = {
  desc: "Text in passenger list item menu for action to remove myself from booking",
  value: "Remove Myself",
  parseSteps: removeMyself_parseSteps
};

var replacePassenger_parseSteps = [];

var replacePassenger = {
  desc: "Text in passenger list item menu for action to replace passenger",
  value: "Replace Passenger",
  parseSteps: replacePassenger_parseSteps
};

var dropBerth_parseSteps = [];

var dropBerth = {
  desc: "Text in passenger list item menu for action to drop berth",
  value: "Drop Berth",
  parseSteps: dropBerth_parseSteps
};

var detachPassenger_parseSteps = [];

var detachPassenger = {
  desc: "Text in passenger list item menu for action to detach passenger",
  value: "Detach Passenger",
  parseSteps: detachPassenger_parseSteps
};

var resendInvitation_parseSteps = [];

var resendInvitation = {
  desc: "Text in passenger list item menu for action to resend invitation",
  value: "Resend invitation",
  parseSteps: resendInvitation_parseSteps
};

var copyInvitationLink_parseSteps = [];

var copyInvitationLink = {
  desc: "Text in passenger list item menu for action to copy invitation link",
  value: "Copy invitation link",
  parseSteps: copyInvitationLink_parseSteps
};

var addPassCopyLink_parseSteps = [];

var addPassCopyLink = {
  desc: "Text in add passenger drawer mobile menu copy link action",
  value: "Copy Link",
  parseSteps: addPassCopyLink_parseSteps
};

var addPassAddPassenger_parseSteps = [];

var addPassAddPassenger = {
  desc: "Text in add passenger drawer mobile menu add passenger action",
  value: "Add Passenger",
  parseSteps: addPassAddPassenger_parseSteps
};

var addPassReplacePassenger_parseSteps = [];

var addPassReplacePassenger = {
  desc: "Text in add/replace passenger drawer mobile menu replace passenger action",
  value: "Replace Passenger",
  parseSteps: addPassReplacePassenger_parseSteps
};

var revokeInvitation_parseSteps = [];

var revokeInvitation = {
  desc: "Text in passenger list item menu for action to revoke invitation",
  value: "Revoke invitation",
  parseSteps: revokeInvitation_parseSteps
};

var addPassDetailsPreferredNamesTitle_parseSteps = [];

var addPassDetailsPreferredNamesTitle = {
  desc: "Add Passenger drawer add details preferred names title",
  value: "Preferred name (optional):",
  parseSteps: addPassDetailsPreferredNamesTitle_parseSteps
};

var addPassDetailsLegalDocumentSectionTitle_parseSteps = [];

var addPassDetailsLegalDocumentSectionTitle = {
  desc: "Add Passenger drawer add details legal document section title",
  value: "Enter information as it appears on their travel document:",
  parseSteps: addPassDetailsLegalDocumentSectionTitle_parseSteps
};

var addPassDetailsFirstName_parseSteps = [];

var addPassDetailsFirstName = {
  desc: "Add Passenger drawer add details first name label",
  value: "First Name",
  parseSteps: addPassDetailsFirstName_parseSteps
};

var addPassDetailsDocFirstName_parseSteps = [];

var addPassDetailsDocFirstName = {
  desc: "Add Passenger drawer add details legal document first name label",
  value: "First Name (travel document)",
  parseSteps: addPassDetailsDocFirstName_parseSteps
};

var addPassDetailsPrefFirstName_parseSteps = [];

var addPassDetailsPrefFirstName = {
  desc: "Add Passenger drawer add details preferred first name label",
  value: "First Name (preferred)",
  parseSteps: addPassDetailsPrefFirstName_parseSteps
};

var addPassDetailsFirstNameRequired_parseSteps = [];

var addPassDetailsFirstNameRequired = {
  desc: "Add Passenger drawer add details first name required label",
  value: "First Name (required)",
  parseSteps: addPassDetailsFirstNameRequired_parseSteps
};

var addPassDetailsLastName_parseSteps = [];

var addPassDetailsLastName = {
  desc: "Add Passenger drawer add details last name label",
  value: "Last Name",
  parseSteps: addPassDetailsLastName_parseSteps
};

var addPassDetailsDocLastName_parseSteps = [];

var addPassDetailsDocLastName = {
  desc: "Add Passenger drawer add details legal document last name label",
  value: "Last Name (travel document)",
  parseSteps: addPassDetailsDocLastName_parseSteps
};

var addPassDetailsPrefLastName_parseSteps = [];

var addPassDetailsPrefLastName = {
  desc: "Add Passenger drawer add details preferred last name label",
  value: "Last Name (preferred)",
  parseSteps: addPassDetailsPrefLastName_parseSteps
};

var addPassDetailsLastNameOptional_parseSteps = [];

var addPassDetailsLastNameOptional = {
  desc: "Add Passenger drawer add details last name optional label",
  value: "Last Name (optional)",
  parseSteps: addPassDetailsLastNameOptional_parseSteps
};

var addPassDetailsEmail_parseSteps = [];

var addPassDetailsEmail = {
  desc: "Add Passenger drawer add details email label",
  value: "Email",
  parseSteps: addPassDetailsEmail_parseSteps
};

var addPassDetailsCellPhone_parseSteps = [];

var addPassDetailsCellPhone = {
  desc: "Add Passenger drawer add details cell phone label",
  value: "Cell Phone",
  parseSteps: addPassDetailsCellPhone_parseSteps
};

var addPassDetailsDocGender_parseSteps = [];

var addPassDetailsDocGender = {
  desc: "Add Passenger drawer add details travel documentgender label",
  value: "Gender (travel document)",
  parseSteps: addPassDetailsDocGender_parseSteps
};

var addPassDetailsGender_parseSteps = [];

var addPassDetailsGender = {
  desc: "Add Passenger drawer add details gender label",
  value: "Gender",
  parseSteps: addPassDetailsGender_parseSteps
};

var addPassDetailsCountry_parseSteps = [];

var addPassDetailsCountry = {
  desc: "Add Passenger drawer add details country label",
  value: "Country of Citizenship",
  parseSteps: addPassDetailsCountry_parseSteps
};

var addPassDetailsBirthdate_parseSteps = [];

var addPassDetailsBirthdate = {
  desc: "Add Passenger drawer add details birthdate label",
  value: "Birthdate",
  parseSteps: addPassDetailsBirthdate_parseSteps
};

var addPassDetailsSecondaryPhone_parseSteps = [];

var addPassDetailsSecondaryPhone = {
  desc: "Add Passenger drawer add details secondary phone label",
  value: "Cell Phone",
  parseSteps: addPassDetailsSecondaryPhone_parseSteps
};

var addPassDetailsResidence_parseSteps = [];

var addPassDetailsResidence = {
  desc: "Add Passenger drawer add details residence label",
  value: "Country of Residence",
  parseSteps: addPassDetailsResidence_parseSteps
};

var addPassDetailsPostalCode_parseSteps = [];

var addPassDetailsPostalCode = {
  desc: "Add Passenger drawer add details postal code label",
  value: "Postal Code",
  parseSteps: addPassDetailsPostalCode_parseSteps
};

var addPassDetailsLoyaltyNumber_parseSteps = [];

var addPassDetailsLoyaltyNumber = {
  desc: "Add Passenger drawer add details loyalty number label",
  value: "Loyalty Number",
  parseSteps: addPassDetailsLoyaltyNumber_parseSteps
};

var addPassDetailsHALMarinerNumber_parseSteps = [];

var addPassDetailsHALMarinerNumber = {
  desc: "Add Passenger drawer add details HAL mariner number label",
  value: "HAL Mariner Number",
  parseSteps: addPassDetailsHALMarinerNumber_parseSteps
};

var addPassInviteEmail_parseSteps = [];

var addPassInviteEmail = {
  desc: "Add Passenger drawer invite via email label",
  value: "Email",
  parseSteps: addPassInviteEmail_parseSteps
};

var addPassInviteSend_parseSteps = [];

var addPassInviteSend = {
  desc: "Add Passenger drawer invite via email send label",
  value: "Send",
  parseSteps: addPassInviteSend_parseSteps
};

var addPassConnectedLabel_parseSteps = [];

var addPassConnectedLabel = {
  desc: "Add Passenger drawer connected account label",
  value: "Passenger",
  parseSteps: addPassConnectedLabel_parseSteps
};

var addPassConnectedPlaceholder_parseSteps = [];

var addPassConnectedPlaceholder = {
  desc: "Add Passenger drawer connected account select account label",
  value: "Select connected account",
  parseSteps: addPassConnectedPlaceholder_parseSteps
};

var addPassConnectedHelperText_parseSteps = [];

var addPassConnectedHelperText = {
  desc: "Add Passenger drawer connected account select account helper text",
  value: "Choose a passenger from the list.",
  parseSteps: addPassConnectedHelperText_parseSteps
};

var addPassConnectedMobileDesc_parseSteps = [];

var addPassConnectedMobileDesc = {
  desc: "Add Passenger drawer connected account mobile description",
  value: "Add a passenger from one of your connected accounts.",
  parseSteps: addPassConnectedMobileDesc_parseSteps
};

var manageBookingButtonText_parseSteps = [];

var manageBookingButtonText = {
  desc: "Button text for manage booking action",
  value: "Manage Booking",
  parseSteps: manageBookingButtonText_parseSteps
};

var draftEditingChip_parseSteps = [];

var draftEditingChip = {
  desc: "Chip text for eraft editing state",
  value: "Editing",
  parseSteps: draftEditingChip_parseSteps
};

var draftBookingHeading_parseSteps = [Merge$Util.Parse.mustache];

var draftBookingHeading = {
  desc: "Heading text for draft booking section",
  value: "Booking ID: {{bookingId}}",
  parseSteps: draftBookingHeading_parseSteps
};

var draftBookingDiscard_parseSteps = [];

var draftBookingDiscard = {
  desc: "Discard button text for draft booking",
  value: "Discard",
  parseSteps: draftBookingDiscard_parseSteps
};

var draftBookingSave_parseSteps = [];

var draftBookingSave = {
  desc: "Save button text for draft booking",
  value: "Save Changes",
  parseSteps: draftBookingSave_parseSteps
};

var errorModalTitle_parseSteps = [];

var errorModalTitle = {
  desc: "Modal title for drafting errors",
  value: "Error",
  parseSteps: errorModalTitle_parseSteps
};

var errorModalDescDefault_parseSteps = [];

var errorModalDescDefault = {
  desc: "Modal description for drafting errors",
  value: "Your requested change could not be applied!",
  parseSteps: errorModalDescDefault_parseSteps
};

var draftBillingFetchError_parseSteps = [];

var draftBillingFetchError = {
  desc: "Description for draft billing data fetch error",
  value: "Draft billing data could not be fetched.",
  parseSteps: draftBillingFetchError_parseSteps
};

var legalUpdateHelpText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var legalUpdateHelpText = {
  desc: "Help text for legal first name on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: legalUpdateHelpText_parseSteps
};

var draftConfirmLoseChangesTitle_parseSteps = [];

var draftConfirmLoseChangesTitle = {
  desc: "Modal title for discarding draft changes",
  value: "Warning: Unsaved Booking Changes",
  parseSteps: draftConfirmLoseChangesTitle_parseSteps
};

var draftConfirmLoseChangesText_parseSteps = [];

var draftConfirmLoseChangesText = {
  desc: "Modal description for discarding draft changes",
  value: "You are about to lose your draft of changes to this booking. Please confirm below.",
  parseSteps: draftConfirmLoseChangesText_parseSteps
};

var draftConfirmLoseChangesButtonText_parseSteps = [];

var draftConfirmLoseChangesButtonText = {
  desc: "Button label for discarding draft changes",
  value: "Lose any unsaved changes",
  parseSteps: draftConfirmLoseChangesButtonText_parseSteps
};

var draftCancelLoseChangesButtonText_parseSteps = [];

var draftCancelLoseChangesButtonText = {
  desc: "Button label for continuing draft changes",
  value: "Keep editing",
  parseSteps: draftCancelLoseChangesButtonText_parseSteps
};

var BookingDetails = {
  emailVerify: emailVerify$1,
  heroLinksViewMore: heroLinksViewMore,
  heroLinksViewFewer: heroLinksViewFewer,
  stateroomDetailsHeading: stateroomDetailsHeading,
  cabinDeckChipText: cabinDeckChipText,
  cabinNumberChipText: cabinNumberChipText,
  berthsChipText: berthsChipText,
  billingContactHeading: billingContactHeading,
  manageReservation: manageReservation,
  upgradeBooking: upgradeBooking,
  downgradeBooking: downgradeBooking,
  changeCabin: changeCabin,
  cancelBooking: cancelBooking,
  billingSummaryHeading: billingSummaryHeading,
  noBillingSummary: noBillingSummary,
  viewBillingDetails: viewBillingDetails,
  totalBilled: totalBilled,
  totalPaid: totalPaid,
  remainingBalance: remainingBalance,
  pendingPayment: pendingPayment,
  balanceAfterPending: balanceAfterPending,
  makeAPayment: makeAPayment,
  billingAndPaymentsTitle: billingAndPaymentsTitle,
  depositScheduleTitle: depositScheduleTitle,
  invoiceTitle: invoiceTitle,
  bookingChargesHeading: bookingChargesHeading,
  paymentsHeading: paymentsHeading,
  noBillingLines: noBillingLines,
  noBillingLinesInactive: noBillingLinesInactive,
  noPaymentLines: noPaymentLines,
  noPaymentLinesInactive: noPaymentLinesInactive,
  descriptionTableHeading: descriptionTableHeading,
  amountTableHeading: amountTableHeading,
  dateTableHeading: dateTableHeading,
  payerTableHeading: payerTableHeading,
  paymentMethodTableHeading: paymentMethodTableHeading,
  totalBilledTableFooter: totalBilledTableFooter,
  pendingBilledTableFooter: pendingBilledTableFooter,
  totalPaidTableFooter: totalPaidTableFooter,
  pendingPaidTableFooter: pendingPaidTableFooter,
  passengerListHeading: passengerListHeading,
  addBerth: addBerth,
  maxOccReached: maxOccReached,
  plTBA: plTBA,
  plStatus: plStatus,
  plName: plName,
  plBirthdate: plBirthdate,
  plEmail: plEmail,
  plConfirmed: plConfirmed,
  plInvited: plInvited,
  plIncomplete: plIncomplete,
  plAttachOnApply: plAttachOnApply,
  plReplaceOnApply: plReplaceOnApply,
  plInviteOnApply: plInviteOnApply,
  plUnknown: plUnknown,
  addPassDrawerTitle: addPassDrawerTitle,
  replacePassDrawerTitle: replacePassDrawerTitle,
  providePassengerDetails: providePassengerDetails,
  providePassengerDetailsDesc: providePassengerDetailsDesc,
  invitePassenger: invitePassenger,
  invitePassengerDesc: invitePassengerDesc,
  selectFromManagedAccounts: selectFromManagedAccounts,
  selectFromManagedAccountsDesc: selectFromManagedAccountsDesc,
  addPassengerByLoyalty: addPassengerByLoyalty,
  addPassengerByLoyaltyDesc: addPassengerByLoyaltyDesc,
  editDetails: editDetails,
  removeMyself: removeMyself,
  replacePassenger: replacePassenger,
  dropBerth: dropBerth,
  detachPassenger: detachPassenger,
  resendInvitation: resendInvitation,
  copyInvitationLink: copyInvitationLink,
  addPassCopyLink: addPassCopyLink,
  addPassAddPassenger: addPassAddPassenger,
  addPassReplacePassenger: addPassReplacePassenger,
  revokeInvitation: revokeInvitation,
  addPassDetailsPreferredNamesTitle: addPassDetailsPreferredNamesTitle,
  addPassDetailsLegalDocumentSectionTitle: addPassDetailsLegalDocumentSectionTitle,
  addPassDetailsFirstName: addPassDetailsFirstName,
  addPassDetailsDocFirstName: addPassDetailsDocFirstName,
  addPassDetailsPrefFirstName: addPassDetailsPrefFirstName,
  addPassDetailsFirstNameRequired: addPassDetailsFirstNameRequired,
  addPassDetailsLastName: addPassDetailsLastName,
  addPassDetailsDocLastName: addPassDetailsDocLastName,
  addPassDetailsPrefLastName: addPassDetailsPrefLastName,
  addPassDetailsLastNameOptional: addPassDetailsLastNameOptional,
  addPassDetailsEmail: addPassDetailsEmail,
  addPassDetailsCellPhone: addPassDetailsCellPhone,
  addPassDetailsDocGender: addPassDetailsDocGender,
  addPassDetailsGender: addPassDetailsGender,
  addPassDetailsCountry: addPassDetailsCountry,
  addPassDetailsBirthdate: addPassDetailsBirthdate,
  addPassDetailsSecondaryPhone: addPassDetailsSecondaryPhone,
  addPassDetailsResidence: addPassDetailsResidence,
  addPassDetailsPostalCode: addPassDetailsPostalCode,
  addPassDetailsLoyaltyNumber: addPassDetailsLoyaltyNumber,
  addPassDetailsHALMarinerNumber: addPassDetailsHALMarinerNumber,
  addPassInviteEmail: addPassInviteEmail,
  addPassInviteSend: addPassInviteSend,
  addPassConnectedLabel: addPassConnectedLabel,
  addPassConnectedPlaceholder: addPassConnectedPlaceholder,
  addPassConnectedHelperText: addPassConnectedHelperText,
  addPassConnectedMobileDesc: addPassConnectedMobileDesc,
  manageBookingButtonText: manageBookingButtonText,
  draftEditingChip: draftEditingChip,
  draftBookingHeading: draftBookingHeading,
  draftBookingDiscard: draftBookingDiscard,
  draftBookingSave: draftBookingSave,
  errorModalTitle: errorModalTitle,
  errorModalDescDefault: errorModalDescDefault,
  draftBillingFetchError: draftBillingFetchError,
  legalUpdateHelpText: legalUpdateHelpText,
  draftConfirmLoseChangesTitle: draftConfirmLoseChangesTitle,
  draftConfirmLoseChangesText: draftConfirmLoseChangesText,
  draftConfirmLoseChangesButtonText: draftConfirmLoseChangesButtonText,
  draftCancelLoseChangesButtonText: draftCancelLoseChangesButtonText
};

var tempManageReservationText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent("Update reservation request for booking " + ctx.bookingId),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var tempManageReservationText = {
  desc: "Manage reservation text for booking details page",
  value: "To make other changes to this reservation, including upgrades and cancelation, please <MailtoLink>contact us</MailtoLink>.",
  parseSteps: tempManageReservationText_parseSteps
};

var tempManageReservationAllLockedText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent("Update reservation request for booking " + ctx.bookingId),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var tempManageReservationAllLockedText = {
  desc: "Manage reservation text for booking details page when all changes are locked",
  value: "To make *any* changes to this reservation, please <MailtoLink>contact us</MailtoLink>.",
  parseSteps: tempManageReservationAllLockedText_parseSteps
};

var tempManagePassengersLockedText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=$" + encodeURIComponent("Update passengers request for booking " + ctx.bookingId),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var tempManagePassengersLockedText = {
  desc: "Manage passengers text for booking details page when changes are locked",
  value: "To make changes to your passenger list, please <MailtoLink>contact us</MailtoLink>.",
  parseSteps: tempManagePassengersLockedText_parseSteps
};

var tempManagePassengersLockedNonBCText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent("Update passengers request for booking " + ctx.bookingId),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var tempManagePassengersLockedNonBCText = {
  desc: "Non-billing contact message for booking details page when changes are locked",
  value: "To make changes to your passenger records, please <MailtoLink>contact us</MailtoLink>.",
  parseSteps: tempManagePassengersLockedNonBCText_parseSteps
};

var tempManagePreferencesLockedText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent("Update preferences request for booking " + ctx.bookingId),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var tempManagePreferencesLockedText = {
  desc: "Manage preferences text for booking details page when changes are locked",
  value: "To make changes to your booking preferences, please <MailtoLink>contact us</MailtoLink>.",
  parseSteps: tempManagePreferencesLockedText_parseSteps
};

var tempManagePlacementPreferencesLockedText_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent("Update cabin placement request for booking " + ctx.bookingId),
                                            forceTargetBlank: true,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var tempManagePlacementPreferencesLockedText = {
  desc: "Manage cabin placement preferences text for booking details page when changes are locked",
  value: "To request changes to your cabin placement, please <MailtoLink>contact us</MailtoLink>.",
  parseSteps: tempManagePlacementPreferencesLockedText_parseSteps
};

var BDTemp = {
  tempManageReservationText: tempManageReservationText,
  tempManageReservationAllLockedText: tempManageReservationAllLockedText,
  tempManagePassengersLockedText: tempManagePassengersLockedText,
  tempManagePassengersLockedNonBCText: tempManagePassengersLockedNonBCText,
  tempManagePreferencesLockedText: tempManagePreferencesLockedText,
  tempManagePlacementPreferencesLockedText: tempManagePlacementPreferencesLockedText
};

var copied_parseSteps = [];

var copied = {
  desc: "Text when something is copied to clipboard",
  value: "Copied to clipboard!",
  parseSteps: copied_parseSteps
};

var copyError_parseSteps = [];

var copyError = {
  desc: "Error message when clipboard copy fails",
  value: "Error copying to clipboard",
  parseSteps: copyError_parseSteps
};

var Clipboard = {
  copied: copied,
  copyError: copyError
};

var connectedAccountsHeading_parseSteps = [];

var connectedAccountsHeading = {
  desc: "Heading text on connected accounts page",
  value: "Connected accounts",
  parseSteps: connectedAccountsHeading_parseSteps
};

var editButton_parseSteps = [];

var editButton = {
  desc: "Edit button text on connected accounts page",
  value: "Edit",
  parseSteps: editButton_parseSteps
};

var transferButton_parseSteps = [];

var transferButton = {
  desc: "Transfer button text on connected accounts page",
  value: "Transfer",
  parseSteps: transferButton_parseSteps
};

var transferButtonHelperText_parseSteps = [];

var transferButtonHelperText = {
  desc: "Helper text for transfer button on connected accounts page",
  value: "Transfer this account to another user.",
  parseSteps: transferButtonHelperText_parseSteps
};

var resendButton_parseSteps = [];

var resendButton = {
  desc: "Resend button text on connected accounts page",
  value: "Resend invitation",
  parseSteps: resendButton_parseSteps
};

var revokeButton_parseSteps = [];

var revokeButton = {
  desc: "Revoke button text on connected accounts page",
  value: "Revoke invitation",
  parseSteps: revokeButton_parseSteps
};

var invitationNoEmail_parseSteps = [];

var invitationNoEmail = {
  desc: "Text when, for some reason, no email address is provided in the invitee field for a transferring connected account",
  value: "another user",
  parseSteps: invitationNoEmail_parseSteps
};

var legalNameLabel_parseSteps = [];

var legalNameLabel = {
  desc: "Label text for legal name on connected accounts page",
  value: "Legal name",
  parseSteps: legalNameLabel_parseSteps
};

var birthdateLabel_parseSteps$1 = [];

var birthdateLabel$1 = {
  desc: "Label text for birthdate on connected accounts page",
  value: "Birthdate",
  parseSteps: birthdateLabel_parseSteps$1
};

var citizenshipLabel_parseSteps$1 = [];

var citizenshipLabel$1 = {
  desc: "Label text for citizenship on connected accounts page",
  value: "Citizenship",
  parseSteps: citizenshipLabel_parseSteps$1
};

var genderLabel_parseSteps$1 = [];

var genderLabel$1 = {
  desc: "Label text for gender on connected accounts page",
  value: "Gender on Travel Documents",
  parseSteps: genderLabel_parseSteps$1
};

var emailLabel_parseSteps$1 = [];

var emailLabel$1 = {
  desc: "Label text for email on connected accounts page",
  value: "Email",
  parseSteps: emailLabel_parseSteps$1
};

var accountIdLabel_parseSteps = [];

var accountIdLabel = {
  desc: "Label text for account id on connected accounts page",
  value: "Account ID",
  parseSteps: accountIdLabel_parseSteps
};

var loyaltyStatusLabel_parseSteps = [];

var loyaltyStatusLabel = {
  desc: "Label text for loyalty status on connected accounts page",
  value: "Loyalty status",
  parseSteps: loyaltyStatusLabel_parseSteps
};

var voyagesLabel_parseSteps = [];

var voyagesLabel = {
  desc: "Label text for voyages on connected accounts page",
  value: "Voyages",
  parseSteps: voyagesLabel_parseSteps
};

var invitationWillExpire_parseSteps = [Merge$Util.Parse.mustache];

var invitationWillExpire = {
  desc: "Message when an invitation has been sent to claim a connected account",
  value: "An invitation has been sent to {{inviteeEmail}} to claim this account. The invitation expires on {{expiryDay}} at {{expiryTime}}. You may resend or revoke the invitation.",
  parseSteps: invitationWillExpire_parseSteps
};

var invitationHasExpired_parseSteps = [Merge$Util.Parse.mustache];

var invitationHasExpired = {
  desc: "Message when an invitation sent to claim a connected account has expired",
  value: "The invitation sent to {{inviteeEmail}} to claim this account has expired. Please resend or revoke the invitation.",
  parseSteps: invitationHasExpired_parseSteps
};

var noValueProvided_parseSteps = [Merge$Util.Parse.mustache];

var noValueProvided = {
  desc: "Text when no value is provided for a field",
  value: "No {{label}} provided",
  parseSteps: noValueProvided_parseSteps
};

var ConnectedAccounts = {
  connectedAccountsHeading: connectedAccountsHeading,
  editButton: editButton,
  transferButton: transferButton,
  transferButtonHelperText: transferButtonHelperText,
  resendButton: resendButton,
  revokeButton: revokeButton,
  invitationNoEmail: invitationNoEmail,
  legalNameLabel: legalNameLabel,
  birthdateLabel: birthdateLabel$1,
  citizenshipLabel: citizenshipLabel$1,
  genderLabel: genderLabel$1,
  emailLabel: emailLabel$1,
  accountIdLabel: accountIdLabel,
  loyaltyStatusLabel: loyaltyStatusLabel,
  voyagesLabel: voyagesLabel,
  invitationWillExpire: invitationWillExpire,
  invitationHasExpired: invitationHasExpired,
  noValueProvided: noValueProvided
};

var transferHeading_parseSteps = [];

var transferHeading = {
  desc: "Heading text on transfer page",
  value: "Claim account transfer",
  parseSteps: transferHeading_parseSteps
};

var transferDescription_parseSteps = [];

var transferDescription = {
  desc: "Description text on transfer page",
  value: "Enter your desired email address and new password below in order to claim the transfer of this account. Please ensure you are not currently logged in to another account.",
  parseSteps: transferDescription_parseSteps
};

var tokenExpiry_parseSteps = [];

var tokenExpiry = {
  desc: "Text transfer page if token has expired.",
  value: "This token has expired. Please request another invitation.",
  parseSteps: tokenExpiry_parseSteps
};

var emailLabel_parseSteps$2 = [];

var emailLabel$2 = {
  desc: "Label text for email field on transfer page",
  value: "Email address to associate with your account",
  parseSteps: emailLabel_parseSteps$2
};

var emailPlaceholder_parseSteps$2 = [];

var emailPlaceholder$2 = {
  desc: "Placeholder text for email field on transfer page",
  value: "Email",
  parseSteps: emailPlaceholder_parseSteps$2
};

var passwordLabel_parseSteps$1 = [];

var passwordLabel$1 = {
  desc: "label for password field on transfer page",
  value: "Choose a password (at least 10 characters)",
  parseSteps: passwordLabel_parseSteps$1
};

var passwordPlaceholder_parseSteps$3 = [];

var passwordPlaceholder$3 = {
  desc: "Placeholder text for password field on transfer page",
  value: "Password",
  parseSteps: passwordPlaceholder_parseSteps$3
};

var confirmPasswordPlaceholder_parseSteps$2 = [];

var confirmPasswordPlaceholder$2 = {
  desc: "Placeholder text for confirm password field on transfer page",
  value: "Confirm password",
  parseSteps: confirmPasswordPlaceholder_parseSteps$2
};

var acceptTransferButton_parseSteps = [];

var acceptTransferButton = {
  desc: "Text for accept transfer button on transfer page",
  value: "Accept transfer",
  parseSteps: acceptTransferButton_parseSteps
};

var transferSuccess_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: ctx.loginLink,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var transferSuccess = {
  desc: "Success message when transfer is claimed",
  value: "You're good to go! Please <LoginLink>login</LoginLink>.",
  parseSteps: transferSuccess_parseSteps
};

var needsVerification_parseSteps = [
  (function (extra) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: "Hydrateable",
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(AppLink$Thin.make, {
                                            to: ctx.loginLink,
                                            children: mustache(value, ctx)
                                          });
                              })
                          };
                  }), extra);
    }),
  Merge$Util.Parse.mustache
];

var needsVerification = {
  desc: "Message when transfer is successful but email needs verification",
  value: "Your email needs to be verified in order to complete account transfer. Please check your inbox for a verification link. In the meantime, you may<LoginLink>login</LoginLink>.",
  parseSteps: needsVerification_parseSteps
};

var Transfer = {
  transferHeading: transferHeading,
  transferDescription: transferDescription,
  tokenExpiry: tokenExpiry,
  emailLabel: emailLabel$2,
  emailPlaceholder: emailPlaceholder$2,
  passwordLabel: passwordLabel$1,
  passwordPlaceholder: passwordPlaceholder$3,
  confirmPasswordPlaceholder: confirmPasswordPlaceholder$2,
  acceptTransferButton: acceptTransferButton,
  transferSuccess: transferSuccess,
  needsVerification: needsVerification
};

var genericFormSubmit_parseSteps = [];

var genericFormSubmit = {
  desc: "Error message when something goes wrong",
  value: "Something went wrong. Please try again.",
  parseSteps: genericFormSubmit_parseSteps
};

var emailInUse_parseSteps = [];

var emailInUse = {
  desc: "Error message when email is already in use",
  value: "This email address is already in use.",
  parseSteps: emailInUse_parseSteps
};

var emailCantBeChanged_parseSteps = [];

var emailCantBeChanged = {
  desc: "Error message when email can't be changed",
  value: "This email address can't be changed. Please contact support.",
  parseSteps: emailCantBeChanged_parseSteps
};

var $$Error = {
  genericFormSubmit: genericFormSubmit,
  emailInUse: emailInUse,
  emailCantBeChanged: emailCantBeChanged
};

exports.Nav = Nav;
exports.Login = Login;
exports.Claim = Claim;
exports.Verify = Verify;
exports.ResetPassword = ResetPassword;
exports.Account = Account;
exports.Bookings = Bookings;
exports.BookingDetails = BookingDetails;
exports.BDTemp = BDTemp;
exports.Clipboard = Clipboard;
exports.ConnectedAccounts = ConnectedAccounts;
exports.Transfer = Transfer;
exports.$$Error = $$Error;
/* Merge-Util Not a pure module */
