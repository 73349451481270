// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var Switch = require("@mui/joy/Switch").default;
var DraftUtils$Thin = require("../../../utils/booking/DraftUtils.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var CountryInput$Thin = require("./CountryInput.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PasswordInput$Thin = require("./PasswordInput.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var PassengerState$Thin = require("../../../utils/passengers/PassengerState.bs.js");
var VoyageMiniCard$Thin = require("./VoyageMiniCard.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var ListItemContent = require("@mui/joy/ListItemContent").default;
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var Cached = require("@mui/icons-material/Cached").default;
var ClaimNewPaxInvitationModel$Thin = require("../../../utils/accounts/ClaimNewPaxInvitationModel.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var ArticleOutlined = require("@mui/icons-material/ArticleOutlined").default;
var PassengerForms_passenger_graphql$Thin = require("../../../__generated__/PassengerForms_passenger_graphql.bs.js");

var convertFragment = PassengerForms_passenger_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PassengerForms_passenger_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PassengerForms_passenger_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(PassengerForms_passenger_graphql$Thin.node, convertFragment, fRef);
}

var PassengerFragment_lifecycleStatus_decode = PassengerForms_passenger_graphql$Thin.Utils.lifecycleStatus_decode;

var PassengerFragment_lifecycleStatus_fromString = PassengerForms_passenger_graphql$Thin.Utils.lifecycleStatus_fromString;

var PassengerFragment = {
  lifecycleStatus_decode: PassengerFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: PassengerFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function PassengerForms$FormWrapper(props) {
  var __tooltip = props.tooltip;
  var showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var subtitle = props.subtitle;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  var tmp;
  tmp = variant === "Neutral" ? "transparent" : "neutral.200";
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 0 : 2;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        mb: showTitleSection ? 2 : 0
                      },
                      children: [
                        showTitleSection ? JsxRuntime.jsx(Typography, {
                                startDecorator: Caml_option.some(props.startDecorator),
                                level: props.titleSize === "md" ? "title-md" : "h4",
                                component: "h2",
                                children: Caml_option.some(props.title)
                              }) : null,
                        subtitle !== undefined ? JsxRuntime.jsxs(Typography, {
                                level: "body-sm",
                                sx: {
                                  py: 1,
                                  color: "neutral.500"
                                },
                                children: [
                                  subtitle,
                                  JsxRuntime.jsx(Typography, {
                                        component: "span",
                                        children: Caml_option.some(tooltip)
                                      })
                                ]
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      sx: {
                        backgroundColor: tmp,
                        p: tmp$1,
                        borderRadius: "4px"
                      },
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var FormWrapper = {
  make: PassengerForms$FormWrapper
};

function PassengerForms$FormControl$HelperText(props) {
  var wasTouched = props.wasTouched;
  var errorText = props.errorText;
  var helperText = props.helperText;
  if (props.restrictedEdit) {
    return JsxRuntime.jsx(FormHelperText, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.legalUpdateHelpText, SysCtx$Thin.vars))
                        }))
              });
  }
  var exit = 0;
  if (helperText !== undefined) {
    exit = 2;
  } else {
    if (errorText === undefined) {
      return null;
    }
    exit = 2;
  }
  if (exit === 2 && errorText !== undefined && wasTouched) {
    return JsxRuntime.jsx(FormHelperText, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          sx: {
                            color: props.error ? "red" : "inherit"
                          },
                          children: Caml_option.some(errorText)
                        }))
              });
  }
  if (helperText !== undefined) {
    return JsxRuntime.jsx(FormHelperText, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          children: Caml_option.some(helperText)
                        }))
              });
  } else {
    return null;
  }
}

var HelperText = {
  make: PassengerForms$FormControl$HelperText
};

function PassengerForms$FormControl(props) {
  var __tooltip = props.tooltip;
  var wasTouched = props.wasTouched;
  var __restrictedEdit = props.restrictedEdit;
  var __required = props.required;
  var __disabled = props.disabled;
  var error = props.error;
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var restrictedEdit = __restrictedEdit !== undefined ? __restrictedEdit : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  return JsxRuntime.jsxs(FormControl, {
              error: wasTouched && error && !disabled,
              required: required,
              children: [
                JsxRuntime.jsx(FormLabel, {
                      required: required,
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                endDecorator: Caml_option.some(tooltip),
                                level: "inherit",
                                children: Caml_option.some(props.label)
                              }))
                    }),
                props.children,
                JsxRuntime.jsx(PassengerForms$FormControl$HelperText, {
                      helperText: props.helperText,
                      errorText: props.errorText,
                      wasTouched: wasTouched,
                      error: error,
                      restrictedEdit: restrictedEdit
                    })
              ]
            });
}

var FormControl$1 = {
  HelperText: HelperText,
  make: PassengerForms$FormControl
};

function PassengerForms$DateInput(props) {
  var set = props.set;
  var match = React.useState(function () {
        return false;
      });
  var setDateFocused = match[1];
  return JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: "date",
              placeholder: props.placeholder,
              disabled: props.disabled,
              variant: props.variant,
              value: props.value,
              onChange: (function (e) {
                  set(function (param) {
                        return e.currentTarget.value;
                      });
                }),
              onFocus: (function (param) {
                  setDateFocused(function (param) {
                        return true;
                      });
                }),
              onBlur: (function (param) {
                  setDateFocused(function (param) {
                        return false;
                      });
                })
            });
}

var DateInput = {
  make: PassengerForms$DateInput
};

function PassengerForms$PhoneInput$CountryCodeSelector(props) {
  var __size = props.size;
  var disabled = props.disabled;
  var size = __size !== undefined ? __size : "md";
  var countryOptions = Belt_Array.keepMap(AppConfig$Thin.Context.use().countries, (function (v) {
          var match = v.callingCode;
          if (match !== undefined) {
            return v;
          }
          
        }));
  return JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
              view: {
                TAG: "Direct",
                _0: props.view
              },
              input: props.phoneCountryCode,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                              children: Caml_option.some(Belt_Array.mapWithIndex(countryOptions, (function (i, c) {
                                          return JsxRuntime.jsx($$Option, {
                                                      value: Caml_option.some(c.callingCode),
                                                      children: Caml_option.some(Belt_Option.getExn(c.callingCode))
                                                    }, String(i));
                                        }))),
                              disabled: disabled,
                              onChange: (function (param, $$do) {
                                  if ($$do === undefined) {
                                    return ;
                                  }
                                  var c = Caml_option.valFromOption($$do);
                                  if (c !== undefined) {
                                    return set(function (param) {
                                                return c;
                                              });
                                  }
                                  
                                }),
                              size: size,
                              slotProps: {
                                listbox: {
                                  sx: {
                                    zIndex: "modal"
                                  }
                                },
                                root: {
                                  sx: {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0
                                  }
                                },
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              },
                              value: param.value,
                              variant: disabled ? "solid" : "soft"
                            });
                })
            });
}

var CountryCodeSelector = {
  make: PassengerForms$PhoneInput$CountryCodeSelector
};

function PassengerForms$PhoneInput(props) {
  var __required = props.required;
  var disabled = props.disabled;
  var phoneCountryCode = props.phoneCountryCode;
  var __variant = props.variant;
  var view = props.view;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var required = __required !== undefined ? __required : false;
  var cc = HopperState$Util.Observable.useComputed(view, (function (v) {
          return phoneCountryCode.getValue(v);
        }), undefined, undefined);
  return JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
              view: {
                TAG: "Direct",
                _0: view
              },
              input: props.phone,
              map: [
                (function (o) {
                    return Belt_Option.getWithDefault(o, "");
                  }),
                (function (s) {
                    if (s === "") {
                      return ;
                    } else {
                      return s;
                    }
                  })
              ],
              children: (function (param) {
                  var set = param.set;
                  var value = param.value;
                  var match = Common$Thin.phoneValidation(cc, value);
                  var tmp;
                  tmp = match.TAG === "Ok" ? false : true;
                  var tmp$1;
                  tmp$1 = variant === "Neutral" ? "outlined" : "plain";
                  return JsxRuntime.jsx(PassengerForms$FormControl, {
                              label: Txt$Thin.BookingDetails.addPassDetailsCellPhone.value,
                              errorText: param.errorText,
                              error: tmp,
                              disabled: disabled,
                              children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                    type_: "phone",
                                    placeholder: Txt$Thin.BookingDetails.addPassDetailsCellPhone.value,
                                    disabled: disabled,
                                    variant: tmp$1,
                                    value: value,
                                    onChange: (function (e) {
                                        set(function (param) {
                                              return e.target.value;
                                            });
                                      }),
                                    startDecorator: Caml_option.some(JsxRuntime.jsx(PassengerForms$PhoneInput$CountryCodeSelector, {
                                              view: view,
                                              phoneCountryCode: phoneCountryCode,
                                              disabled: disabled
                                            })),
                                    slotProps: {
                                      root: {
                                        sx: {
                                          pl: 0
                                        }
                                      }
                                    }
                                  }),
                              required: required,
                              wasTouched: param.wasTouched
                            });
                })
            });
}

var PhoneInput = {
  CountryCodeSelector: CountryCodeSelector,
  make: PassengerForms$PhoneInput
};

function PassengerForms$InlineToggle(props) {
  var __restrictedEdit = props.restrictedEdit;
  var helperText = props.helperText;
  var __size = props.size;
  var size = __size !== undefined ? __size : "lg";
  var restrictedEdit = __restrictedEdit !== undefined ? __restrictedEdit : false;
  return JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
              view: {
                TAG: "Direct",
                _0: props.view
              },
              input: props.input,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(Switch, {
                                      checked: param.value,
                                      onChange: (function (e) {
                                          set(function (param) {
                                                return e.currentTarget.checked;
                                              });
                                        }),
                                      size: size,
                                      sx: {
                                        alignSelf: "flex-start"
                                      }
                                    }),
                                JsxRuntime.jsx(PassengerForms$FormControl$HelperText, {
                                      helperText: helperText,
                                      errorText: param.errorText,
                                      wasTouched: param.wasTouched,
                                      error: param.error,
                                      restrictedEdit: restrictedEdit
                                    })
                              ]
                            });
                })
            });
}

var InlineToggle = {
  make: PassengerForms$InlineToggle
};

function $$interface(view) {
  return {
          view: view,
          docFirstName: PassengerState$Thin.docFirstName,
          docLastName: PassengerState$Thin.docLastName,
          docGender: PassengerState$Thin.docGender,
          docCitizenship: PassengerState$Thin.docCitizenship,
          docBirthdate: PassengerState$Thin.docBirthdate
        };
}

var PassengerState = {
  $$interface: $$interface
};

function $$interface$1(view) {
  return {
          view: view,
          docFirstName: ClaimNewPaxInvitationModel$Thin.docFirstName,
          docLastName: ClaimNewPaxInvitationModel$Thin.docLastName,
          docGender: ClaimNewPaxInvitationModel$Thin.docGender,
          docCitizenship: ClaimNewPaxInvitationModel$Thin.docCitizenship,
          docBirthdate: ClaimNewPaxInvitationModel$Thin.docBirthdate,
          sameAs: {
            docFirstSameAsPreferredFirst: ClaimNewPaxInvitationModel$Thin.docFirstSameAsPreferredFirst,
            docLastSameAsPreferredLast: ClaimNewPaxInvitationModel$Thin.docLastSameAsPreferredLast
          }
        };
}

var ClaimNewPaxInvitation = {
  $$interface: $$interface$1
};

function PassengerForms$Travel$SameAsToggle(props) {
  var docLastSameAsPreferredLast = props.docLastSameAsPreferredLast;
  var docFirstSameAsPreferredFirst = props.docFirstSameAsPreferredFirst;
  var view = props.view;
  var checked = HopperState$Util.Observable.useComputed(view, (function (v) {
          if (docFirstSameAsPreferredFirst.getValue(v)) {
            return docLastSameAsPreferredLast.getValue(v);
          } else {
            return false;
          }
        }), undefined, undefined);
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              alignItems: "center",
              spacing: 2,
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      alignItems: "center",
                      children: Caml_option.some(JsxRuntime.jsx(Switch, {
                                checked: checked,
                                onChange: (function (e) {
                                    var b = e.currentTarget.checked;
                                    HopperState$Util.Observable.notify(view, (function (v) {
                                            return docLastSameAsPreferredLast.set(docFirstSameAsPreferredFirst.set(v, b, undefined), b, undefined);
                                          }));
                                  }),
                                sx: {
                                  alignSelf: "flex-start"
                                }
                              }))
                    }),
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      children: Caml_option.some(props.helperText)
                    })
              ]
            });
}

var SameAsToggle = {
  make: PassengerForms$Travel$SameAsToggle
};

function PassengerForms$Travel(props) {
  var match = props.interface;
  var docFirstName = match.docFirstName;
  if (docFirstName !== undefined) {
    var docLastName = match.docLastName;
    if (docLastName !== undefined) {
      var docBirthdate = match.docBirthdate;
      if (docBirthdate !== undefined) {
        var __isSelf = props.isSelf;
        var spacing = props.spacing;
        var __restrictedEdit = props.restrictedEdit;
        var pax1MustBeBornBy = props.pax1MustBeBornBy;
        var __showTitleSection = props.showTitleSection;
        var disabled = props.disabled;
        var __variant = props.variant;
        var __titleSize = props.titleSize;
        var __title = props.title;
        var sameAs = match.sameAs;
        var view = match.view;
        var title = __title !== undefined ? __title : "Travel Document";
        var titleSize = __titleSize !== undefined ? __titleSize : "lg";
        var variant = __variant !== undefined ? __variant : "Neutral";
        var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
        var restrictedEdit = __restrictedEdit !== undefined ? __restrictedEdit : "Never";
        var isSelf = __isSelf !== undefined ? __isSelf : false;
        var match$1 = HopperState$Util.Observable.useComputed(view, (function (model) {
                return [
                        Belt_Option.isSome(docFirstName.getInitialValue(model)),
                        Belt_Option.isSome(docLastName.getInitialValue(model)),
                        Belt_Option.isSome(docBirthdate.getInitialValue(model))
                      ];
              }), undefined, undefined);
        var match$2;
        switch (restrictedEdit) {
          case "Always" :
              match$2 = [
                true,
                true,
                true
              ];
              break;
          case "WhenNotNull" :
              match$2 = [
                match$1[0],
                match$1[1],
                match$1[2]
              ];
              break;
          case "Never" :
              match$2 = [
                false,
                false,
                false
              ];
              break;
          
        }
        var restrictedBirthdate = match$2[2];
        var restrictedLast = match$2[1];
        var restrictedFirst = match$2[0];
        var sameNames = Belt_Option.mapWithDefault(sameAs, false, (function (param) {
                var docLastSameAsPreferredLast = param.docLastSameAsPreferredLast;
                var docFirstSameAsPreferredFirst = param.docFirstSameAsPreferredFirst;
                return HopperState$Util.Observable.useComputed(view, (function (v) {
                              if (docFirstSameAsPreferredFirst.getValue(v)) {
                                return docLastSameAsPreferredLast.getValue(v);
                              } else {
                                return false;
                              }
                            }), undefined, undefined);
              }));
        var subtitle = "The information in this section must exactly match the government-issued travel document that " + (
          isSelf ? "you" : "the passenger"
        ) + " will use to board the cruise.";
        var nameTooltip = JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(IconButton, {
                    sx: {
                      minHeight: "0",
                      minWidth: "0",
                      "&:hover": {
                        background: "none"
                      }
                    },
                    children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                              fontSize: "inherit"
                            }))
                  }),
              placement: "top",
              size: "sm",
              sx: {
                maxWidth: "300px"
              },
              title: "You can provide different travel document information on each voyage. For account security reasons, if you need to update a travel document name or birthdate, you must contact customer service."
            });
        var tmp;
        tmp = variant === "Neutral" ? 1 : 2;
        var tmp$1;
        tmp$1 = variant === "Neutral" ? 1 : 2;
        return JsxRuntime.jsx(PassengerForms$FormWrapper, {
                    startDecorator: JsxRuntime.jsx(ArticleOutlined, {}),
                    title: title,
                    titleSize: titleSize,
                    subtitle: subtitle,
                    variant: variant,
                    showTitleSection: showTitleSection,
                    tooltip: Caml_option.some(nameTooltip),
                    children: JsxRuntime.jsxs(Joy$Util.Grid.make, {
                          children: [
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(Belt_Option.mapWithDefault(sameAs, null, (function (param) {
                                              if (restrictedFirst || restrictedLast) {
                                                return null;
                                              } else {
                                                return JsxRuntime.jsx(PassengerForms$Travel$SameAsToggle, {
                                                            view: view,
                                                            docFirstSameAsPreferredFirst: param.docFirstSameAsPreferredFirst,
                                                            docLastSameAsPreferredLast: param.docLastSameAsPreferredLast,
                                                            helperText: "My travel document name is the same as my preferred name, above."
                                                          });
                                              }
                                            }))),
                                  xs: 12
                                }),
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                            view: {
                                              TAG: "Direct",
                                              _0: view
                                            },
                                            input: docFirstName,
                                            map: [
                                              (function (o) {
                                                  return Belt_Option.getWithDefault(o, "");
                                                }),
                                              (function (s) {
                                                  if (s === "") {
                                                    return ;
                                                  } else {
                                                    return s;
                                                  }
                                                })
                                            ],
                                            children: (function (param) {
                                                var set = param.set;
                                                var tmp;
                                                tmp = variant === "Neutral" ? "outlined" : "plain";
                                                return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                            label: Txt$Thin.BookingDetails.addPassDetailsDocFirstName.value,
                                                            errorText: param.errorText,
                                                            error: param.error,
                                                            disabled: disabled || restrictedFirst || sameNames,
                                                            children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                  type_: "text",
                                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsDocFirstName.value,
                                                                  disabled: disabled || restrictedFirst || sameNames,
                                                                  variant: tmp,
                                                                  value: param.value,
                                                                  onChange: (function (e) {
                                                                      set(function (param) {
                                                                            return e.target.value;
                                                                          });
                                                                    })
                                                                }),
                                                            required: true,
                                                            restrictedEdit: restrictedFirst,
                                                            wasTouched: param.wasTouched
                                                          });
                                              })
                                          })),
                                  xs: 12,
                                  md: 6
                                }),
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                            view: {
                                              TAG: "Direct",
                                              _0: view
                                            },
                                            input: docLastName,
                                            map: [
                                              (function (o) {
                                                  return Belt_Option.getWithDefault(o, "");
                                                }),
                                              (function (s) {
                                                  if (s === "") {
                                                    return ;
                                                  } else {
                                                    return s;
                                                  }
                                                })
                                            ],
                                            children: (function (param) {
                                                var set = param.set;
                                                var tmp;
                                                tmp = variant === "Neutral" ? "outlined" : "plain";
                                                return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                            label: Txt$Thin.BookingDetails.addPassDetailsDocLastName.value,
                                                            errorText: param.errorText,
                                                            error: param.error,
                                                            disabled: disabled || restrictedLast || sameNames,
                                                            children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                  type_: "text",
                                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsDocLastName.value,
                                                                  disabled: disabled || restrictedLast || sameNames,
                                                                  variant: tmp,
                                                                  value: param.value,
                                                                  onChange: (function (e) {
                                                                      set(function (param) {
                                                                            return e.target.value;
                                                                          });
                                                                    })
                                                                }),
                                                            required: true,
                                                            restrictedEdit: restrictedLast,
                                                            wasTouched: param.wasTouched
                                                          });
                                              })
                                          })),
                                  xs: 12,
                                  md: 6
                                }),
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                            view: {
                                              TAG: "Direct",
                                              _0: view
                                            },
                                            input: match.docGender,
                                            children: (function (param) {
                                                var set = param.set;
                                                var tmp;
                                                tmp = variant === "Neutral" ? "outlined" : "plain";
                                                return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                            label: Txt$Thin.BookingDetails.addPassDetailsDocGender.value,
                                                            errorText: param.errorText,
                                                            error: param.error,
                                                            disabled: disabled,
                                                            children: JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                  children: Caml_option.some(Belt_Array.mapWithIndex(DraftUtils$Thin.Pax.genderOptions, (function (i, g) {
                                                                              return JsxRuntime.jsx($$Option, {
                                                                                          value: g,
                                                                                          children: Caml_option.some(DraftUtils$Thin.Pax.genderToText(g))
                                                                                        }, String(i));
                                                                            }))),
                                                                  disabled: disabled,
                                                                  onChange: (function (param, $$do) {
                                                                      set(function (param) {
                                                                            return Belt_Option.getWithDefault($$do, undefined);
                                                                          });
                                                                    }),
                                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsDocGender.value,
                                                                  slotProps: {
                                                                    listbox: {
                                                                      sx: {
                                                                        zIndex: "modal"
                                                                      }
                                                                    }
                                                                  },
                                                                  value: param.value,
                                                                  variant: tmp
                                                                }),
                                                            required: true,
                                                            wasTouched: param.wasTouched
                                                          });
                                              })
                                          })),
                                  xs: 12,
                                  md: 6
                                }),
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                            view: {
                                              TAG: "Direct",
                                              _0: view
                                            },
                                            input: match.docCitizenship,
                                            children: (function (param) {
                                                var set = param.set;
                                                var tmp;
                                                tmp = variant === "Neutral" ? "outlined" : "plain";
                                                return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                            label: Txt$Thin.BookingDetails.addPassDetailsCountry.value,
                                                            errorText: param.errorText,
                                                            error: param.error,
                                                            disabled: disabled,
                                                            children: JsxRuntime.jsx(CountryInput$Thin.make, {
                                                                  disabled: disabled,
                                                                  variant: tmp,
                                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsCountry.value,
                                                                  value: param.value,
                                                                  onChange: (function (param, $$do) {
                                                                      set(function (param) {
                                                                            return Belt_Option.getWithDefault($$do, undefined);
                                                                          });
                                                                    })
                                                                }),
                                                            required: true,
                                                            wasTouched: param.wasTouched
                                                          });
                                              })
                                          })),
                                  xs: 12,
                                  md: 6
                                }),
                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                            view: {
                                              TAG: "Direct",
                                              _0: view
                                            },
                                            input: docBirthdate,
                                            map: [
                                              (function (o) {
                                                  return Belt_Option.getWithDefault(o, "");
                                                }),
                                              (function (s) {
                                                  if (s === "") {
                                                    return ;
                                                  } else {
                                                    return s;
                                                  }
                                                })
                                            ],
                                            children: (function (param) {
                                                var errorText = param.errorText;
                                                var result = param.result;
                                                var paxBirthdateShouldError;
                                                if (result.TAG === "Ok") {
                                                  var dob = result._0;
                                                  paxBirthdateShouldError = dob !== undefined && pax1MustBeBornBy !== undefined ? DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(pax1MustBeBornBy)), DateOnly$Util.toDate(Caml_option.valFromOption(dob))) : false;
                                                } else {
                                                  paxBirthdateShouldError = false;
                                                }
                                                var pax1MustBeBornByText = Belt_Option.map(pax1MustBeBornBy, (function (pax1MustBeBornBy) {
                                                        return "Birthdate for this passenger must be on or before " + DateFns.format(CS_Make$Util.Dateable.Plain.toDate(pax1MustBeBornBy), SysCtx$Thin.vars.dateFormat);
                                                      }));
                                                var tmp;
                                                tmp = variant === "Neutral" ? "outlined" : "plain";
                                                return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                            label: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                            helperText: pax1MustBeBornByText,
                                                            errorText: errorText !== undefined ? errorText : pax1MustBeBornByText,
                                                            error: param.error || paxBirthdateShouldError,
                                                            disabled: disabled || restrictedBirthdate,
                                                            children: JsxRuntime.jsx(PassengerForms$DateInput, {
                                                                  placeholder: Txt$Thin.BookingDetails.addPassDetailsBirthdate.value,
                                                                  value: param.value,
                                                                  set: param.set,
                                                                  disabled: disabled || restrictedBirthdate,
                                                                  variant: tmp
                                                                }),
                                                            required: true,
                                                            restrictedEdit: restrictedBirthdate,
                                                            wasTouched: param.wasTouched
                                                          });
                                              })
                                          })),
                                  xs: 12,
                                  md: 6
                                })
                          ],
                          container: true,
                          spacing: {
                            TAG: "Fixed",
                            _0: 1
                          },
                          rowSpacing: Belt_Option.getWithDefault(spacing, tmp),
                          columnSpacing: Belt_Option.getWithDefault(spacing, tmp$1)
                        })
                  });
      }
      
    }
    
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "_none_",
          1,
          -1
        ],
        Error: new Error()
      };
}

var Travel = {
  PassengerState: PassengerState,
  ClaimNewPaxInvitation: ClaimNewPaxInvitation,
  SameAsToggle: SameAsToggle,
  make: PassengerForms$Travel
};

function $$interface$2(view) {
  return {
          view: view,
          preferredFirstName: PassengerState$Thin.preferredFirstName,
          preferredLastName: PassengerState$Thin.preferredLastName,
          postalCode: PassengerState$Thin.postalCode,
          residenceCountry: PassengerState$Thin.residenceCountry,
          acquisitionSlug: PassengerState$Thin.acquisitionSlug,
          phone: PassengerState$Thin.phone,
          phoneCountryCode: PassengerState$Thin.phoneCountryCode,
          halMarinerNumber: PassengerState$Thin.halMarinerNumber,
          validatePhone: PassengerState$Thin.validatePhone
        };
}

var PassengerState$1 = {
  $$interface: $$interface$2
};

function $$interface$3(view) {
  return {
          view: view,
          preferredFirstName: ClaimNewPaxInvitationModel$Thin.preferredFirstName,
          preferredLastName: ClaimNewPaxInvitationModel$Thin.preferredLastName,
          postalCode: ClaimNewPaxInvitationModel$Thin.postalCode,
          residenceCountry: ClaimNewPaxInvitationModel$Thin.residenceCountry,
          acquisitionSlug: ClaimNewPaxInvitationModel$Thin.acquisitionSlug,
          phone: ClaimNewPaxInvitationModel$Thin.phone,
          phoneCountryCode: ClaimNewPaxInvitationModel$Thin.phoneCountryCode,
          halMarinerNumber: ClaimNewPaxInvitationModel$Thin.halMarinerNumber,
          validatePhone: ClaimNewPaxInvitationModel$Thin.validatePhone
        };
}

var ClaimNewPaxInvitation$1 = {
  $$interface: $$interface$3
};

function PassengerForms$PersonalFields(props) {
  var __requireResidenceCountry = props.requireResidenceCountry;
  var __requirePostalCode = props.requirePostalCode;
  var __requirePhone = props.requirePhone;
  var __requireNames = props.requireNames;
  var __isConnectedAcct = props.isConnectedAcct;
  var __trackAcquisition = props.trackAcquisition;
  var disabled = props.disabled;
  var __variant = props.variant;
  var match = props.interface;
  var view = match.view;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var trackAcquisition = __trackAcquisition !== undefined ? __trackAcquisition : false;
  var isConnectedAcct = __isConnectedAcct !== undefined ? __isConnectedAcct : false;
  var requireNames = __requireNames !== undefined ? __requireNames : false;
  var requirePhone = __requirePhone !== undefined ? __requirePhone : false;
  var requirePostalCode = __requirePostalCode !== undefined ? __requirePostalCode : false;
  var requireResidenceCountry = __requireResidenceCountry !== undefined ? __requireResidenceCountry : false;
  var match$1 = AppConfig$Thin.Context.use();
  var acquisitionOptions = match$1.acquisitionOptions;
  var nameTooltip = JsxRuntime.jsx(Tooltip, {
        children: JsxRuntime.jsx(IconButton, {
              sx: {
                minHeight: "0",
                minWidth: "0",
                "&:hover": {
                  background: "none"
                }
              },
              children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                        fontSize: "inherit"
                      }))
            }),
        placement: "top",
        size: "sm",
        sx: {
          maxWidth: "300px"
        },
        title: "Preferred name does not need to match a government-issued travel document (e.g. passport). We'll address you by your preferred name as often as possible within this website, in our communications to you, and during your cruise."
      });
  var halTooltip = JsxRuntime.jsx(Tooltip, {
        children: JsxRuntime.jsx(IconButton, {
              sx: {
                minHeight: "0",
                minWidth: "0",
                "&:hover": {
                  background: "none"
                }
              },
              children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                        fontSize: "inherit"
                      }))
            }),
        placement: "top",
        size: "sm",
        sx: {
          maxWidth: "300px"
        },
        title: "Add your Holland America Line Mariner Number to get applicable credit with Holland America's loyalty program."
      });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: match.preferredFirstName,
                                map: [
                                  (function (o) {
                                      return Belt_Option.getWithDefault(o, "");
                                    }),
                                  (function (s) {
                                      if (s === "") {
                                        return ;
                                      } else {
                                        return s;
                                      }
                                    })
                                ],
                                children: (function (param) {
                                    var set = param.set;
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                label: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                errorText: param.errorText,
                                                error: param.error,
                                                children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefFirstName.value,
                                                      disabled: disabled,
                                                      variant: tmp,
                                                      value: param.value,
                                                      onChange: (function (e) {
                                                          set(function (param) {
                                                                return e.target.value;
                                                              });
                                                        })
                                                    }),
                                                required: requireNames,
                                                wasTouched: param.wasTouched,
                                                tooltip: Caml_option.some(nameTooltip)
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: match.preferredLastName,
                                map: [
                                  (function (o) {
                                      return Belt_Option.getWithDefault(o, "");
                                    }),
                                  (function (s) {
                                      if (s === "") {
                                        return ;
                                      } else {
                                        return s;
                                      }
                                    })
                                ],
                                children: (function (param) {
                                    var set = param.set;
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                label: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                errorText: param.errorText,
                                                error: param.error,
                                                children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsPrefLastName.value,
                                                      disabled: disabled,
                                                      variant: tmp,
                                                      value: param.value,
                                                      onChange: (function (e) {
                                                          set(function (param) {
                                                                return e.target.value;
                                                              });
                                                        })
                                                    }),
                                                required: requireNames,
                                                wasTouched: param.wasTouched,
                                                tooltip: Caml_option.some(nameTooltip)
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                isConnectedAcct ? null : JsxRuntime.jsx(Joy$Util.Grid.make, {
                        children: Caml_option.some(JsxRuntime.jsx(PassengerForms$PhoneInput, {
                                  view: view,
                                  variant: variant,
                                  phone: match.phone,
                                  phoneCountryCode: match.phoneCountryCode,
                                  disabled: disabled,
                                  required: requirePhone
                                })),
                        xs: 12,
                        md: 6
                      }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: match.postalCode,
                                map: [
                                  (function (o) {
                                      return Belt_Option.getWithDefault(o, "");
                                    }),
                                  (function (s) {
                                      if (s === "") {
                                        return ;
                                      } else {
                                        return s;
                                      }
                                    })
                                ],
                                children: (function (param) {
                                    var set = param.set;
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                label: Txt$Thin.BookingDetails.addPassDetailsPostalCode.value,
                                                errorText: param.errorText,
                                                error: param.error,
                                                children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsPostalCode.value,
                                                      disabled: disabled,
                                                      variant: tmp,
                                                      value: param.value,
                                                      onChange: (function (e) {
                                                          set(function (param) {
                                                                return e.target.value;
                                                              });
                                                        })
                                                    }),
                                                required: requirePostalCode,
                                                wasTouched: param.wasTouched
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: match.residenceCountry,
                                children: (function (param) {
                                    var set = param.set;
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                label: Txt$Thin.BookingDetails.addPassDetailsResidence.value,
                                                errorText: param.errorText,
                                                error: param.error,
                                                children: JsxRuntime.jsx(CountryInput$Thin.make, {
                                                      disabled: disabled,
                                                      variant: tmp,
                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsResidence.value,
                                                      value: param.value,
                                                      onChange: (function (param, $$do) {
                                                          set(function (param) {
                                                                return Belt_Option.getWithDefault($$do, undefined);
                                                              });
                                                        })
                                                    }),
                                                required: requireResidenceCountry,
                                                wasTouched: param.wasTouched
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: view
                                },
                                input: match.halMarinerNumber,
                                map: [
                                  (function (o) {
                                      return Belt_Option.getWithDefault(o, "");
                                    }),
                                  (function (s) {
                                      if (s === "") {
                                        return ;
                                      } else {
                                        return s;
                                      }
                                    })
                                ],
                                children: (function (param) {
                                    var set = param.set;
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                label: Txt$Thin.BookingDetails.addPassDetailsHALMarinerNumber.value,
                                                errorText: param.errorText,
                                                error: param.error,
                                                children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: Txt$Thin.BookingDetails.addPassDetailsHALMarinerNumber.value,
                                                      disabled: disabled,
                                                      variant: tmp,
                                                      value: param.value,
                                                      onChange: (function (e) {
                                                          set(function (param) {
                                                                return e.target.value;
                                                              });
                                                        })
                                                    }),
                                                wasTouched: param.wasTouched,
                                                tooltip: Caml_option.some(halTooltip)
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(trackAcquisition ? JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                  view: {
                                    TAG: "Direct",
                                    _0: view
                                  },
                                  input: match.acquisitionSlug,
                                  children: (function (param) {
                                      var set = param.set;
                                      var tmp;
                                      tmp = variant === "Neutral" ? "outlined" : "plain";
                                      return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                  label: "How did you find out about us?",
                                                  errorText: param.errorText,
                                                  error: param.error,
                                                  children: JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                        children: Caml_option.some(Belt_Array.map(acquisitionOptions, (function (param) {
                                                                    var desc = param.desc;
                                                                    var slug = param.slug;
                                                                    return JsxRuntime.jsx($$Option, {
                                                                                value: Caml_option.some(slug),
                                                                                children: Caml_option.some(JsxRuntime.jsx(ListItemContent, {
                                                                                          children: Caml_option.some(desc),
                                                                                          sx: {
                                                                                            fontSize: "sm"
                                                                                          }
                                                                                        })),
                                                                                label: desc,
                                                                                sx: {
                                                                                  maxWidth: "calc(100vw - 32px)",
                                                                                  overflow: "hidden",
                                                                                  textOverflow: "ellipsis"
                                                                                }
                                                                              }, CS_Slugs$Util.Slug.toString(slug));
                                                                  }))),
                                                        disabled: disabled,
                                                        onChange: (function (param, $$do) {
                                                            set(function (param) {
                                                                  return Belt_Option.getWithDefault($$do, undefined);
                                                                });
                                                          }),
                                                        placeholder: "Select an option",
                                                        required: true,
                                                        slotProps: {
                                                          listbox: {
                                                            sx: {
                                                              zIndex: "modal"
                                                            }
                                                          }
                                                        },
                                                        value: param.value,
                                                        variant: tmp
                                                      }),
                                                  required: true,
                                                  wasTouched: param.wasTouched
                                                });
                                    })
                                }) : null),
                      xs: 12,
                      md: 6
                    })
              ]
            });
}

var PersonalFields = {
  PassengerState: PassengerState$1,
  ClaimNewPaxInvitation: ClaimNewPaxInvitation$1,
  make: PassengerForms$PersonalFields
};

function PassengerForms$Personal(props) {
  var __trackAcquisition = props.trackAcquisition;
  var __interface = props.interface;
  var __isConnectedAcct = props.isConnectedAcct;
  var __variant = props.variant;
  var __showTitleSection = props.showTitleSection;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "User Profile";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var isConnectedAcct = __isConnectedAcct !== undefined ? __isConnectedAcct : false;
  var $$interface$4 = __interface !== undefined ? __interface : $$interface$2(props.pax);
  var trackAcquisition = __trackAcquisition !== undefined ? __trackAcquisition : false;
  var tmp;
  tmp = variant === "Neutral" ? 1 : 2;
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsx(PassengerForms$FormWrapper, {
              startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: JsxRuntime.jsx(Joy$Util.Grid.make, {
                    children: Caml_option.some(JsxRuntime.jsx(PassengerForms$PersonalFields, {
                              interface: $$interface$4,
                              variant: variant,
                              disabled: props.disabled,
                              trackAcquisition: trackAcquisition,
                              isConnectedAcct: isConnectedAcct
                            })),
                    container: true,
                    spacing: {
                      TAG: "Fixed",
                      _0: 1
                    },
                    rowSpacing: tmp,
                    columnSpacing: tmp$1
                  })
            });
}

var Personal = {
  make: PassengerForms$Personal
};

function PassengerForms$Voyages(props) {
  var pax = props.pax;
  var setShowVoyageOptions = props.setShowVoyageOptions;
  var showVoyageOptions = props.showVoyageOptions;
  var bookings = props.bookings;
  var voyagesData = use(props.fragmentRefs);
  var voyOpt;
  voyOpt = bookings.TAG === "Account" ? Belt_Array.keepMap(bookings._0, (function (param) {
            var bookings = param.bookings;
            if (bookings === undefined) {
              return ;
            }
            var match = bookings.bookingListitem;
            if (match === undefined) {
              return ;
            }
            var match$1 = match.voyage;
            if (match$1 === undefined) {
              return ;
            }
            var tmp = bookings.lifecycleStatus;
            if (!(tmp === "CANCELED" || tmp === "STAGED" || tmp === "DRAFT" || tmp === "ACTIVE" || tmp === "HISTORY")) {
              return ;
            }
            if (tmp !== "ACTIVE") {
              return ;
            }
            var voyStartDate = match$1.voyStartDate;
            if (!DateFns.isBefore(CS_Make$Util.Dateable.Plain.toDate(CS_Make$Util.Dateable.Plain.Exn.fromPrimitive(voyStartDate)), new Date())) {
              return {
                      voyageSlug: match$1.voyageSlug,
                      voyageName: match$1.voyageName,
                      voyStartDate: CS_SQLDatesAndTimes$Util.DateOnly.Exn.fromPrimitive(voyStartDate),
                      voyEndDate: CS_SQLDatesAndTimes$Util.DateOnly.Exn.fromPrimitive(match$1.voyEndDate),
                      imageSetSlug: match$1.imageSetSlug
                    };
            }
            
          })) : Belt_Array.keepMap(voyagesData.nodes, (function (param) {
            var bookings = param.bookings;
            if (bookings === undefined) {
              return ;
            }
            var match = bookings.bookingListitem;
            if (match === undefined) {
              return ;
            }
            var match$1 = match.voyage;
            if (match$1 === undefined) {
              return ;
            }
            var tmp = bookings.lifecycleStatus;
            if (!(tmp === "CANCELED" || tmp === "STAGED" || tmp === "DRAFT" || tmp === "ACTIVE" || tmp === "HISTORY")) {
              return ;
            }
            if (tmp !== "ACTIVE") {
              return ;
            }
            var voyStartDate = match$1.voyStartDate;
            if (!DateFns.isBefore(voyStartDate, new Date())) {
              return {
                      voyageSlug: match$1.voyageSlug,
                      voyageName: match$1.voyageName,
                      voyStartDate: voyStartDate,
                      voyEndDate: match$1.voyEndDate,
                      imageSetSlug: match$1.imageSetSlug
                    };
            }
            
          }));
  var selectedVoyages = HopperState$Util.Observable.useComputed(pax, (function (pax) {
          return pax.updateOtherVoyages;
        }), undefined, undefined);
  var isVoyageSelected = function (s) {
    return Belt_Array.some(selectedVoyages, (function (voy) {
                  return voy === s;
                }));
  };
  React.useEffect((function () {
          if (showVoyageOptions) {
            HopperState$Util.Observable.notify(pax, (function (p) {
                    var newrecord = Caml_obj.obj_dup(p);
                    newrecord.updateOtherVoyages = Belt_Array.concat(p.updateOtherVoyages, Belt_Array.keepMap(voyOpt, (function (voy) {
                                return Caml_option.some(voy.voyageSlug);
                              })));
                    return newrecord;
                  }));
          } else {
            HopperState$Util.Observable.notify(pax, (function (p) {
                    var newrecord = Caml_obj.obj_dup(p);
                    newrecord.updateOtherVoyages = [];
                    return newrecord;
                  }));
          }
        }), [showVoyageOptions]);
  var match = voyOpt.length !== 0;
  if (props.display && match) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          cursor: "pointer"
                        },
                        onClick: (function (param) {
                            setShowVoyageOptions(function (param) {
                                  return !showVoyageOptions;
                                });
                          }),
                        children: [
                          JsxRuntime.jsx(Checkbox, {
                                checked: showVoyageOptions,
                                size: "sm"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                children: "Apply changes to bookings"
                              })
                        ]
                      }),
                  showVoyageOptions ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                          children: Caml_option.some(Belt_Array.mapWithIndex(voyOpt, (function (i, v) {
                                      return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(VoyageMiniCard$Thin.make, {
                                                            voyage: v,
                                                            onSelect: (function () {
                                                                var s = v.voyageSlug;
                                                                if (isVoyageSelected(s)) {
                                                                  return HopperState$Util.Observable.notify(pax, (function (p) {
                                                                                var newrecord = Caml_obj.obj_dup(p);
                                                                                newrecord.updateOtherVoyages = Belt_Array.keep(p.updateOtherVoyages, (function (voy) {
                                                                                        return voy !== s;
                                                                                      }));
                                                                                return newrecord;
                                                                              }));
                                                                } else {
                                                                  return HopperState$Util.Observable.notify(pax, (function (p) {
                                                                                var newrecord = Caml_obj.obj_dup(p);
                                                                                newrecord.updateOtherVoyages = Belt_Array.concat(p.updateOtherVoyages, [s]);
                                                                                return newrecord;
                                                                              }));
                                                                }
                                                              }),
                                                            isSelected: isVoyageSelected(v.voyageSlug)
                                                          })),
                                                  xs: 12,
                                                  md: 6
                                                }, String(i));
                                    }))),
                          container: true,
                          rowSpacing: 1,
                          columnSpacing: 1
                        }) : null
                ]
              });
  } else {
    return null;
  }
}

var Voyages = {
  make: PassengerForms$Voyages
};

function PassengerForms$SignUp(props) {
  var signUp = props.signUp;
  var setSignUpError = props.setSignUpError;
  var setLoginError = props.setLoginError;
  var signUpError = props.signUpError;
  var __loading = props.loading;
  var disabled = props.disabled;
  var __variant = props.variant;
  var __signedUp = props.signedUp;
  var pax = props.pax;
  var __showTitleSection = props.showTitleSection;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Sign Up";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var signedUp = __signedUp !== undefined ? __signedUp : false;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var loading = __loading !== undefined ? __loading : false;
  var model = HopperState$Util.Observable.getRoot(pax, "RetainSkipUpdateFromView");
  var signup = SFState$Thin.signupView(model);
  var passwordsMatch = HopperState$Util.Observable.useComputed(signup, (function (signup) {
          return Caml_obj.equal(SFState$Thin.password.validate(signup, undefined), SFState$Thin.confirmPassword.validate(signup, undefined));
        }), undefined, undefined);
  HopperState$Util.Observable.useComputed(signup, (function (signup) {
          return Belt_Result.isError(SFState$Thin.password.validate(signup, undefined));
        }), undefined, undefined);
  var onSubmit = function (e) {
    e.preventDefault();
    setLoginError(function (param) {
          
        });
    setSignUpError(function (param) {
          
        });
    var input = SFState$Thin.validateSignupFormField(HopperState$Util.Observable.dangerouslyRead(model), HopperState$Util.Observable.dangerouslyRead(pax));
    if (input.TAG === "Ok") {
      signUp(input._0);
    } else {
      setSignUpError(function (param) {
            return "An error occurred signing up! Please try again.";
          });
    }
  };
  var blockSignUp = HopperState$Util.Observable.useComputed(model, (function (model) {
          var bc = model.bc;
          if (bc !== undefined) {
            return Belt_Result.isError(SFState$Thin.validateSignupFormField(model, bc));
          } else {
            return false;
          }
        }), undefined, undefined);
  var tmp;
  tmp = variant === "Neutral" ? 1 : 2;
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 1 : 2;
  var tmp$2;
  tmp$2 = variant === "Neutral" ? 1 : 2;
  var tmp$3;
  tmp$3 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsx(PassengerForms$FormWrapper, {
              startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    component: "form",
                    spacing: 3,
                    onSubmit: onSubmit,
                    children: [
                      JsxRuntime.jsxs(Stack, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    sx: {
                                      pb: 2
                                    },
                                    children: "Account Access"
                                  }),
                              JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                    children: [
                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                            children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                                                      view: {
                                                        TAG: "Direct",
                                                        _0: pax
                                                      },
                                                      input: PassengerState$Thin.email,
                                                      map: [
                                                        (function (o) {
                                                            return Belt_Option.getWithDefault(o, "");
                                                          }),
                                                        (function (s) {
                                                            if (s === "") {
                                                              return ;
                                                            } else {
                                                              return s;
                                                            }
                                                          })
                                                      ],
                                                      children: (function (param) {
                                                          var set = param.set;
                                                          var tmp;
                                                          tmp = variant === "Neutral" ? "outlined" : "plain";
                                                          return JsxRuntime.jsx(PassengerForms$FormControl, {
                                                                      label: Txt$Thin.BookingDetails.addPassDetailsEmail.value,
                                                                      errorText: param.errorText,
                                                                      error: param.error,
                                                                      children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                            type_: "text",
                                                                            placeholder: Txt$Thin.BookingDetails.addPassDetailsEmail.value,
                                                                            disabled: disabled,
                                                                            variant: tmp,
                                                                            value: param.value,
                                                                            onChange: (function (e) {
                                                                                set(function (param) {
                                                                                      return e.target.value;
                                                                                    });
                                                                              })
                                                                          }),
                                                                      required: true,
                                                                      wasTouched: param.wasTouched
                                                                    });
                                                        })
                                                    })),
                                            xs: 12,
                                            md: 6
                                          }),
                                      JsxRuntime.jsx(Joy$Util.Grid.make, {
                                            sx: {
                                              display: {
                                                xs: "none",
                                                md: "block"
                                              }
                                            },
                                            md: 6
                                          }),
                                      signedUp ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                                                view: {
                                                                  TAG: "Direct",
                                                                  _0: signup
                                                                },
                                                                input: SFState$Thin.password,
                                                                children: (function (param) {
                                                                    var set = param.set;
                                                                    return JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                                placeholder: "Password",
                                                                                disabled: disabled,
                                                                                required: true,
                                                                                error: param.error && param.wasTouched && !disabled,
                                                                                value: param.value,
                                                                                onChange: (function (e) {
                                                                                    set(function (param) {
                                                                                          return e.target.value;
                                                                                        });
                                                                                  }),
                                                                                autoComplete: "new-password",
                                                                                errorText: param.errorText,
                                                                                helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                                                label: "Password"
                                                                              });
                                                                  })
                                                              })),
                                                      xs: 12,
                                                      md: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.make, {
                                                                view: {
                                                                  TAG: "Direct",
                                                                  _0: signup
                                                                },
                                                                input: SFState$Thin.confirmPassword,
                                                                children: (function (param) {
                                                                    var set = param.set;
                                                                    return JsxRuntime.jsx(PasswordInput$Thin.make, {
                                                                                placeholder: "Confirm Password",
                                                                                disabled: disabled,
                                                                                required: true,
                                                                                error: !passwordsMatch && param.wasTouched && !disabled,
                                                                                value: param.value,
                                                                                onChange: (function (e) {
                                                                                    set(function (param) {
                                                                                          return e.target.value;
                                                                                        });
                                                                                  }),
                                                                                autoComplete: "new-password",
                                                                                errorText: passwordsMatch ? undefined : "The passwords you entered do not match",
                                                                                helperText: "Must be at least " + String(Common$Thin.passwordLength) + " characters",
                                                                                label: "Confirm Password"
                                                                              });
                                                                  })
                                                              })),
                                                      xs: 12,
                                                      md: 6
                                                    })
                                              ]
                                            })
                                    ],
                                    container: true,
                                    spacing: {
                                      TAG: "Fixed",
                                      _0: 1
                                    },
                                    rowSpacing: tmp,
                                    columnSpacing: tmp$1
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Stack, {
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    sx: {
                                      pb: 2
                                    },
                                    children: "Personal Information"
                                  }),
                              JsxRuntime.jsx(Joy$Util.Grid.make, {
                                    children: Caml_option.some(JsxRuntime.jsx(PassengerForms$PersonalFields, {
                                              interface: $$interface$2(pax),
                                              variant: variant,
                                              disabled: disabled,
                                              trackAcquisition: true,
                                              requireNames: true,
                                              requirePhone: true,
                                              requirePostalCode: true,
                                              requireResidenceCountry: true
                                            })),
                                    container: true,
                                    spacing: {
                                      TAG: "Fixed",
                                      _0: 1
                                    },
                                    rowSpacing: tmp$2,
                                    columnSpacing: tmp$3
                                  })
                            ]
                          }),
                      signedUp ? null : JsxRuntime.jsx(Stack, {
                              spacing: 2,
                              sx: {
                                width: "fit-content"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                        loading: loading,
                                        type: "submit",
                                        loadingIndicator: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                                                  variant: "solid"
                                                })),
                                        endDecorator: Caml_option.some(JsxRuntime.jsx(Cached, {
                                                  sx: {
                                                    transform: "rotate(15deg)"
                                                  }
                                                })),
                                        size: "lg",
                                        disabled: disabled || blockSignUp,
                                        children: "Create Account"
                                      }))
                            }),
                      signUpError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: JsxRuntime.jsx(JsxRuntime.Fragment, {
                                    children: Caml_option.some(signUpError)
                                  }),
                              type_: "error"
                            }) : null
                    ]
                  })
            });
}

var SignUp = {
  make: PassengerForms$SignUp
};

exports.PassengerFragment = PassengerFragment;
exports.FormWrapper = FormWrapper;
exports.FormControl = FormControl$1;
exports.DateInput = DateInput;
exports.PhoneInput = PhoneInput;
exports.InlineToggle = InlineToggle;
exports.Travel = Travel;
exports.PersonalFields = PersonalFields;
exports.Personal = Personal;
exports.Voyages = Voyages;
exports.SignUp = SignUp;
/* react Not a pure module */
