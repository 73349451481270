// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../common/atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../common/atoms/AppButton.bs.js");
var Divider = require("@mui/joy/Divider").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var MyAccountState$Thin = require("../../utils/accounts/MyAccountState.bs.js");
var PassengerForms$Thin = require("../common/molecules/PassengerForms.bs.js");
var PassengerState$Thin = require("../../utils/passengers/PassengerState.bs.js");
var UpdateAccountNew$Thin = require("../common/mutations/UpdateAccountNew.bs.js");

function PersonalAndTravelInformation$Modify(props) {
  var setError = props.setError;
  var setIsEditing = props.setIsEditing;
  var isEditing = props.isEditing;
  var updateUserAccount = props.updateUserAccount;
  var pax = props.pax;
  var __actionText = props.actionText;
  var fields = props.fields;
  var actionText = __actionText !== undefined ? __actionText : Txt$Thin.Account.modifyProfileButton.value;
  var isDirty = HopperState$Util.Observable.FormField.useIsDirty(pax, PassengerState$Thin.paxUtils);
  var shouldError = HopperState$Util.Observable.useComputed(pax, (function (pax) {
          return Belt_Result.isError(PassengerState$Thin.validateUpdateAccount(pax, fields));
        }), undefined, undefined);
  var onClick = function (e) {
    e.preventDefault();
    var input = PassengerState$Thin.validateUpdateAccount(HopperState$Util.Observable.dangerouslyRead(pax), fields);
    if (input.TAG === "Ok") {
      return updateUserAccount(input._0);
    }
    var err = input._0;
    setError(function (param) {
          return err;
        });
  };
  if (isEditing) {
    return JsxRuntime.jsxs(Stack, {
                direction: "row",
                spacing: 1,
                children: [
                  JsxRuntime.jsx(AppButton$Thin.make, {
                        loading: props.isMutating && isEditing,
                        onClick: onClick,
                        color: "primary",
                        disabled: !isDirty || shouldError,
                        children: Caml_option.some(Txt$Thin.Account.saveButton.value)
                      }),
                  JsxRuntime.jsx(AppButton$Thin.make, {
                        onClick: (function (param) {
                            setIsEditing(function (param) {
                                  return false;
                                });
                            HopperState$Util.Observable.FormField.resetToInitialValue(pax, PassengerState$Thin.paxUtils);
                          }),
                        variant: "outlined",
                        color: "neutral",
                        children: Caml_option.some(Txt$Thin.Account.cancelButton.value)
                      })
                ]
              });
  } else {
    return JsxRuntime.jsx(Joy$Util.Link.make, {
                children: actionText,
                level: "body-sm",
                component: "button",
                color: "primary",
                onClick: (function (param) {
                    setIsEditing(function (param) {
                          return true;
                        });
                  }),
                sx: {
                  cursor: "pointer",
                  textDecoration: "underline"
                }
              });
  }
}

var Modify = {
  make: PersonalAndTravelInformation$Modify
};

function PersonalAndTravelInformation(props) {
  var refetch = props.refetch;
  var match = React.useState(function () {
        return false;
      });
  var setIsEditingPersonal = match[1];
  var isEditingPersonal = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsEditingTravel = match$1[1];
  var isEditingTravel = match$1[0];
  var match$2 = React.useState(function () {
        return true;
      });
  var setShowVoyageOptions = match$2[1];
  var onSuccess = function (param) {
    setIsEditingPersonal(function (param) {
          return false;
        });
    setIsEditingTravel(function (param) {
          return false;
        });
    setShowVoyageOptions(function (param) {
          return true;
        });
    refetch();
  };
  var match$3 = UpdateAccountNew$Thin.useMutation(onSuccess);
  var updateUserAccount = match$3.updateUserAccount;
  var setError = match$3.setError;
  var error = match$3.error;
  var isMutating = match$3.isMutating;
  var pax = MyAccountState$Thin.accountView(props.accountState);
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 2,
                      sx: {
                        py: 2
                      },
                      children: [
                        JsxRuntime.jsx(PassengerForms$Thin.Personal.make, {
                              subtitle: "Tell us about yourself.",
                              pax: pax,
                              disabled: !isEditingPersonal
                            }),
                        JsxRuntime.jsx(PersonalAndTravelInformation$Modify, {
                              fields: "Personal",
                              pax: pax,
                              updateUserAccount: updateUserAccount,
                              isMutating: isMutating,
                              isEditing: isEditingPersonal,
                              setIsEditing: setIsEditingPersonal,
                              setError: setError
                            })
                      ]
                    }),
                error !== undefined && isEditingPersonal ? JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : null,
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsxs(Stack, {
                      spacing: 2,
                      sx: {
                        py: 2
                      },
                      children: [
                        JsxRuntime.jsx(PassengerForms$Thin.Travel.make, {
                              interface: PassengerForms$Thin.Travel.PassengerState.$$interface(pax),
                              subtitle: "Information below must exactly match your travel document (e.g. passport). We will address you by your preferred name (above) whenever possible.",
                              disabled: !isEditingTravel,
                              restrictedEdit: "WhenNotNull",
                              isSelf: true
                            }),
                        JsxRuntime.jsx(PassengerForms$Thin.Voyages.make, {
                              fragmentRefs: props.paxRecordFrag,
                              bookings: props.bookings,
                              showVoyageOptions: match$2[0],
                              setShowVoyageOptions: setShowVoyageOptions,
                              pax: pax,
                              display: isEditingTravel
                            }),
                        JsxRuntime.jsx(PersonalAndTravelInformation$Modify, {
                              fields: "Travel",
                              actionText: "Modify your travel documentation",
                              pax: pax,
                              updateUserAccount: updateUserAccount,
                              isMutating: isMutating,
                              isEditing: isEditingTravel,
                              setIsEditing: setIsEditingTravel,
                              setError: setError
                            }),
                        error !== undefined && isEditingTravel ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                message: error,
                                type_: "error"
                              }) : null
                      ]
                    })
              ]
            });
}

var make = PersonalAndTravelInformation;

exports.Modify = Modify;
exports.make = make;
/* react Not a pure module */
