// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../../common/molecules/Dialog.bs.js");
var Box = require("@mui/joy/Box").default;
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Radio = require("@mui/joy/Radio").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var WaveLoader$Thin = require("../../common/atoms/WaveLoader.bs.js");
var SalesHeading$Thin = require("../shared/SalesHeading.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RadioGroup = require("@mui/joy/RadioGroup").default;
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var OccupancySelector$Thin = require("./OccupancySelector.bs.js");
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var ClassAndSubclassSelector$Thin = require("./ClassAndSubclassSelector.bs.js");

function ClassAndSubclassSelection$UnavailableBanner(props) {
  var occupancy = props.occupancy;
  var unavailableAtOccupancyText = "There are currently no cabins available for " + String(occupancy) + " " + (
    occupancy === 1 ? "guest" : "guests"
  ) + " on JoCo Cruise 2026.  Cabins may become available at a later time. If you'd like to join us, be sure to ";
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(AppAlert$Thin.make, {
                        message: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                props.unavailableForAllOccupancies ? "There are currently no cabins available on JoCo Cruise 2026. Cabins may become available at a later time. If you'd like to join us, be sure to " : unavailableAtOccupancyText,
                                JsxRuntime.jsx(Joy$Util.Link.make, {
                                      children: " fill out the 2026 waitlist.",
                                      href: "https://jococruise.com/2026-wait-list/",
                                      target: "_blank",
                                      endDecorator: Caml_option.some(JsxRuntime.jsx(OpenInNew, {}))
                                    })
                              ]
                            }),
                        type_: "warning",
                        size: "sm"
                      }))
            });
}

var UnavailableBanner = {
  make: ClassAndSubclassSelection$UnavailableBanner
};

function ClassAndSubclassSelection(props) {
  var setPricingMode = props.setPricingMode;
  var pricingMode = props.pricingMode;
  var sessionData = props.sessionData;
  var hasCabinHold = props.hasCabinHold;
  var voyage = props.voyage;
  var classes = props.classes;
  var occupancy = props.occupancy;
  var urlState = props.urlState;
  var url = props.url;
  var flow = props.flow;
  var match = React.useState(function () {
        return false;
      });
  var setHoldWarningModalOpen = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setReleaseCabinHoldAction = match$1[1];
  var releaseCabinHoldAction = match$1[0];
  var holdWarningOrProceed = function (shouldWarn, action) {
    var act = function (param) {
      action();
      setHoldWarningModalOpen(function (param) {
            return false;
          });
    };
    if (shouldWarn) {
      setHoldWarningModalOpen(function (param) {
            return true;
          });
      return setReleaseCabinHoldAction(function (param) {
                  return act;
                });
    } else {
      return action();
    }
  };
  var tmp;
  var exit = 0;
  if (voyage !== undefined && CS_Slugs$Util.VoyageSlug.toString(voyage.voyageSlug) !== "2026") {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = props.allUnavailable ? JsxRuntime.jsx(ClassAndSubclassSelection$UnavailableBanner, {
            unavailableForAllOccupancies: true,
            occupancy: occupancy
          }) : (
        props.unavailableForOccupancy ? JsxRuntime.jsx(ClassAndSubclassSelection$UnavailableBanner, {
                unavailableForAllOccupancies: false,
                occupancy: occupancy
              }) : null
      );
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 1,
                      sx: {
                        px: {
                          xs: 2,
                          md: 4
                        },
                        py: 2,
                        flex: 1,
                        height: "100%"
                      },
                      children: [
                        tmp,
                        JsxRuntime.jsx(SalesHeading$Thin.make, {
                              title: "Select a stateroom class",
                              description: "Select your desired occupancy, class, and subclass.",
                              voyage: voyage
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              spacing: 2,
                              sx: {
                                py: 1
                              },
                              children: [
                                JsxRuntime.jsx(OccupancySelector$Thin.make, {
                                      flow: flow,
                                      url: url,
                                      occupancy: occupancy,
                                      urlState: urlState,
                                      hasCabinHold: hasCabinHold,
                                      holdWarningOrProceed: holdWarningOrProceed
                                    }),
                                JsxRuntime.jsx(Stack, {
                                      direction: "row",
                                      justifyContent: "space-between",
                                      spacing: 1,
                                      sx: {
                                        backgroundColor: "background.surface",
                                        border: "1px solid",
                                        borderColor: "neutral.200",
                                        borderRadius: "8px",
                                        width: "fit-content"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Box, {
                                                children: Caml_option.some(JsxRuntime.jsx(RadioGroup, {
                                                          orientation: "horizontal",
                                                          "aria-labelledby": "segmented-controls-example",
                                                          name: "pricemode",
                                                          value: pricingMode,
                                                          onChange: (function (ev) {
                                                              var newVal = ev.currentTarget.value;
                                                              setPricingMode(function (param) {
                                                                    return newVal;
                                                                  });
                                                            }),
                                                          sx: {
                                                            minHeight: 36,
                                                            padding: "4px",
                                                            borderRadius: "12px",
                                                            bgcolor: "background.surface",
                                                            "--RadioGroup-gap": "4px",
                                                            "--Radio-actionRadius": "8px"
                                                          },
                                                          size: "sm",
                                                          children: Caml_option.some(Belt_Array.map([
                                                                    "Total price",
                                                                    "Price per berth"
                                                                  ], (function (item) {
                                                                      return JsxRuntime.jsx(Radio, {
                                                                                  color: "neutral",
                                                                                  disableIcon: true,
                                                                                  value: item,
                                                                                  label: item,
                                                                                  slotProps: {
                                                                                    action: {
                                                                                      sx: {
                                                                                        bgcolor: pricingMode === item ? "primary.500" : "",
                                                                                        "&:hover": {
                                                                                          bgcolor: pricingMode === item ? "primary.500" : ""
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    label: {
                                                                                      sx: {
                                                                                        color: pricingMode === item ? "neutral.softBg" : ""
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  sx: {
                                                                                    px: 2,
                                                                                    alignItems: "center"
                                                                                  },
                                                                                  variant: "plain"
                                                                                }, item);
                                                                    })))
                                                        })),
                                                sx: {
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: 2
                                                }
                                              }))
                                    })
                              ]
                            }),
                        classes !== undefined ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(Belt_Array.mapWithIndex(classes, (function (i, c) {
                                            return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                        children: Caml_option.some(JsxRuntime.jsx(ClassAndSubclassSelector$Thin.make, {
                                                                  class: c,
                                                                  urlState: urlState,
                                                                  occupancy: occupancy,
                                                                  flow: flow,
                                                                  url: url,
                                                                  sessionData: sessionData,
                                                                  hasCabinHold: hasCabinHold,
                                                                  holdWarningOrProceed: holdWarningOrProceed,
                                                                  pricingMode: pricingMode
                                                                })),
                                                        sx: {
                                                          px: {
                                                            xs: 0,
                                                            sm: 1
                                                          }
                                                        },
                                                        xs: 12,
                                                        sm: 6,
                                                        md: 4
                                                      }, String(i));
                                          }))),
                                container: true,
                                rowSpacing: 2,
                                columnSpacing: 2
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  flex: 1,
                                  height: "100%"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(WaveLoader$Thin.make, {}))
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Dialog$Thin.make, {
                      open_: match[0],
                      title: "Cabin Hold Warning",
                      onClose: (function () {
                          setHoldWarningModalOpen(function (param) {
                                return false;
                              });
                        }),
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        level: "body-md",
                                        fontWeight: "400",
                                        children: "You currently have a cabin on hold. If you proceed, the hold will be released. Do you want to continue?"
                                      })),
                              sx: {
                                p: 2
                              }
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: {
                                          xs: "column-reverse",
                                          sm: "row"
                                        },
                                        justifyContent: "space-between",
                                        spacing: 1,
                                        sx: {
                                          width: "100%"
                                        },
                                        children: [
                                          JsxRuntime.jsx(Button, {
                                                onClick: (function (param) {
                                                    setHoldWarningModalOpen(function (param) {
                                                          return false;
                                                        });
                                                  }),
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                onClick: (function (param) {
                                                    if (releaseCabinHoldAction !== undefined) {
                                                      return releaseCabinHoldAction();
                                                    }
                                                    
                                                  }),
                                                color: "primary",
                                                children: "Proceed"
                                              })
                                        ]
                                      }))
                            })
                      ],
                      noGap: true,
                      zIndex: 1301
                    })
              ]
            });
}

var make = ClassAndSubclassSelection;

exports.UnavailableBanner = UnavailableBanner;
exports.make = make;
/* react Not a pure module */
